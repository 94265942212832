import React, { useState } from 'react';
import {
	Box,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Collapse,
	IconButton,
	Drawer,
	useMediaQuery,
	Avatar,
	Typography,
} from '@mui/material';
import {
	Dashboard,
	Person,
	Settings,
	MonetizationOn,
	HowToVote,
	AccountBalanceWallet,
	BarChart,
	CallReceived,
	CallMade,
	People,
	Support,
	ExpandLess,
	ExpandMore,
	ExitToApp,
	MoreHoriz,
	ArrowForward,
	CircleNotifications,
	SwapHoriz,
	Menu,
	Close,
} from '@mui/icons-material';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useAuth } from '../../hooks/AuthContext';

const SidebarContainer = styled(Box)`
	width: 250px;
	height: 100vh; /* Full viewport height */
	background-color: #3e7661;
	color: #fff;
	overflow-y: auto; /* Enables vertical scrolling */
`;

const SidebarHeader = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1rem;
	background-color: #5c6975;
`;

const SubItemIcon = styled(MoreHoriz)`
	color: #ccc;
	margin: 0px 20px;
`;

const SubItemArrow = styled(ArrowForward)`
	color: #f39c12;
	margin: 0px 20px;
`;

const SubItem = styled(ListItem)`
	position: relative;
	display: flex;
	align-items: center;
	&:hover {
		background-color: #34495e;
	}
	&:hover .MuiListItemIcon-root .sub-item-arrow {
		display: block;
	}
	& .MuiListItemIcon-root .sub-item-arrow {
		display: none;
	}
	& .MuiListItemIcon-root .sub-item-icon {
		display: block;
	}
	&:hover .MuiListItemIcon-root .sub-item-icon {
		display: none;
	}
`;

const Sidebar = () => {
	const isMobile = useMediaQuery('(max-width:600px)');
	const [open, setOpen] = useState({});
	const [drawerOpen, setDrawerOpen] = useState(false);
	const { logout, profile } = useAuth();

	const handleClick = (menu) => {
		setOpen((prevState) => ({ ...prevState, [menu]: !prevState[menu] }));
	};
	const toggleDrawer = (open) => (event) => {
		if (
			event.type === 'keydown' &&
			(event.key === 'Tab' || event.key === 'Shift')
		) {
			return;
		}
		setDrawerOpen(open);
	};

	const handleLogout = () => {
		logout();
	};
	const menuItems = (
		<List>
			<ListItem button component={Link} to='/dashboard'>
				<ListItemIcon>
					<Dashboard style={{ color: '#fff' }} />
				</ListItemIcon>
				<ListItemText primary='Dashboard' />
			</ListItem>

			{/* Profile Section */}
			<ListItem button onClick={() => handleClick('profile')}>
				<ListItemIcon>
					<Person style={{ color: '#fff' }} />
				</ListItemIcon>
				<ListItemText primary='Profile' />
				{open.profile ? <ExpandLess /> : <ExpandMore />}
			</ListItem>
			<Collapse in={open.profile} timeout='auto' unmountOnExit>
				<List component='div' disablePadding>
					<SubItem button component={Link} to='/dashboard/my-profile'>
						<ListItemIcon>
							<SubItemIcon className='sub-item-icon' />
							<SubItemArrow className='sub-item-arrow' />
						</ListItemIcon>
						<ListItemText primary='My Profile' />
					</SubItem>
					<SubItem button component={Link} to='/dashboard/kyc'>
						<ListItemIcon>
							<SubItemIcon className='sub-item-icon' />
							<SubItemArrow className='sub-item-arrow' />
						</ListItemIcon>
						<ListItemText primary='KYC' />
					</SubItem>
					<SubItem button component={Link} to='/dashboard/welcome-letter'>
						<ListItemIcon>
							<SubItemIcon className='sub-item-icon' />
							<SubItemArrow className='sub-item-arrow' />
						</ListItemIcon>
						<ListItemText primary='Welcome Letter' />
					</SubItem>
				</List>
			</Collapse>

			<ListItem button component={Link} to='/dashboard/account-settings'>
				<ListItemIcon>
					<Settings style={{ color: '#fff' }} />
				</ListItemIcon>
				<ListItemText primary='Account Setting' />
			</ListItem>

			{/* Activate Id Section */}
			<ListItem button onClick={() => handleClick('activate')}>
				<ListItemIcon>
					<HowToVote style={{ color: '#fff' }} />
				</ListItemIcon>
				<ListItemText primary='Activate Id' />
				{open.activate ? <ExpandLess /> : <ExpandMore />}
			</ListItem>
			<Collapse in={open.activate} timeout='auto' unmountOnExit>
				<List component='div' disablePadding>
					<SubItem button component={Link} to='/dashboard/activate'>
						<ListItemIcon>
							<SubItemIcon className='sub-item-icon' />
							<SubItemArrow className='sub-item-arrow' />
						</ListItemIcon>
						<ListItemText primary='Activate' />
					</SubItem>
					<SubItem button component={Link} to='/dashboard/activate-history'>
						<ListItemIcon>
							<SubItemIcon className='sub-item-icon' />
							<SubItemArrow className='sub-item-arrow' />
						</ListItemIcon>
						<ListItemText primary='Activate History' />
					</SubItem>
				</List>
			</Collapse>

			{/* ReTop Up ID Section */}
			<ListItem button onClick={() => handleClick('retopup')}>
				<ListItemIcon>
					<CallReceived style={{ color: '#fff' }} />
				</ListItemIcon>
				<ListItemText primary='ReTop Up ID' />
				{open.retopup ? <ExpandLess /> : <ExpandMore />}
			</ListItem>
			<Collapse in={open.retopup} timeout='auto' unmountOnExit>
				<List component='div' disablePadding>
					<SubItem button component={Link} to='/dashboard/retopup-id'>
						<ListItemIcon>
							<SubItemIcon className='sub-item-icon' />
							<SubItemArrow className='sub-item-arrow' />
						</ListItemIcon>
						<ListItemText primary='ReTop Up ID' />
					</SubItem>
					<SubItem button component={Link} to='/dashboard/retopup-id-history'>
						<ListItemIcon>
							<SubItemIcon className='sub-item-icon' />
							<SubItemArrow className='sub-item-arrow' />
						</ListItemIcon>
						<ListItemText primary='ReTop Up ID History' />
					</SubItem>
				</List>
			</Collapse>

			{/* Withdraw Section */}
			<ListItem button onClick={() => handleClick('withdraw')}>
				<ListItemIcon>
					<CallMade style={{ color: '#fff' }} />
				</ListItemIcon>
				<ListItemText primary='Withdraw' />
				{open.withdraw ? <ExpandLess /> : <ExpandMore />}
			</ListItem>
			<Collapse in={open.withdraw} timeout='auto' unmountOnExit>
				<List component='div' disablePadding>
					<SubItem button component={Link} to='/dashboard/withdraw'>
						<ListItemIcon>
							<SubItemIcon className='sub-item-icon' />
							<SubItemArrow className='sub-item-arrow' />
						</ListItemIcon>
						<ListItemText primary='Withdraw' />
					</SubItem>
					<SubItem button component={Link} to='/dashboard/withdraw-history'>
						<ListItemIcon>
							<SubItemIcon className='sub-item-icon' />
							<SubItemArrow className='sub-item-arrow' />
						</ListItemIcon>
						<ListItemText primary='Withdraw History' />
					</SubItem>
				</List>
			</Collapse>

			{/* Deposit Section */}
			<ListItem button onClick={() => handleClick('deposit')}>
				<ListItemIcon>
					<AccountBalanceWallet style={{ color: '#fff' }} />
				</ListItemIcon>
				<ListItemText primary='Deposit' />
				{open.deposit ? <ExpandLess /> : <ExpandMore />}
			</ListItem>
			<Collapse in={open.deposit} timeout='auto' unmountOnExit>
				<List component='div' disablePadding>
					<SubItem button component={Link} to='/dashboard/deposit-usdt'>
						<ListItemIcon>
							<SubItemIcon className='sub-item-icon' />
							<SubItemArrow className='sub-item-arrow' />
						</ListItemIcon>
						<ListItemText primary='Deposit USDT' />
					</SubItem>
					<SubItem button component={Link} to='/dashboard/deposit-history'>
						<ListItemIcon>
							<SubItemIcon className='sub-item-icon' />
							<SubItemArrow className='sub-item-arrow' />
						</ListItemIcon>
						<ListItemText primary='Deposit History' />
					</SubItem>
				</List>
			</Collapse>

			{/* Transfer Section */}
			<ListItem button onClick={() => handleClick('transfer')}>
				<ListItemIcon>
					<SwapHoriz style={{ color: '#fff' }} />
				</ListItemIcon>
				<ListItemText primary='Transfer' />
				{open.transfer ? <ExpandLess /> : <ExpandMore />}
			</ListItem>
			<Collapse in={open.transfer} timeout='auto' unmountOnExit>
				<List component='div' disablePadding>
					<SubItem button component={Link} to='/dashboard/transfer-to-wallet'>
						<ListItemIcon>
							<SubItemIcon className='sub-item-icon' />
							<SubItemArrow className='sub-item-arrow' />
						</ListItemIcon>
						<ListItemText primary='Transfer To Wallet' />
					</SubItem>
					<SubItem button component={Link} to='/dashboard/transfer-history'>
						<ListItemIcon>
							<SubItemIcon className='sub-item-icon' />
							<SubItemArrow className='sub-item-arrow' />
						</ListItemIcon>
						<ListItemText primary='Transfer History' />
					</SubItem>
				</List>
			</Collapse>

			{/* Payouts Section */}
			<ListItem button onClick={() => handleClick('payouts')}>
				<ListItemIcon>
					<MonetizationOn style={{ color: '#fff' }} />
				</ListItemIcon>
				<ListItemText primary='Payouts' />
				{open.payouts ? <ExpandLess /> : <ExpandMore />}
			</ListItem>
			<Collapse in={open.payouts} timeout='auto' unmountOnExit>
				<List component='div' disablePadding>
					<SubItem button component={Link} to='/dashboard/referral-bonus'>
						<ListItemIcon>
							<SubItemIcon className='sub-item-icon' />
							<SubItemArrow className='sub-item-arrow' />
						</ListItemIcon>
						<ListItemText primary='Referral Bonus' />
					</SubItem>
					<SubItem button component={Link} to='/dashboard/welfare-fund'>
						<ListItemIcon>
							<SubItemIcon className='sub-item-icon' />
							<SubItemArrow className='sub-item-arrow' />
						</ListItemIcon>
						<ListItemText primary='Welfare Fund' />
					</SubItem>
					<SubItem
						button
						component={Link}
						to='/dashboard/level-subscription-earning'>
						<ListItemIcon>
							<SubItemIcon className='sub-item-icon' />
							<SubItemArrow className='sub-item-arrow' />
						</ListItemIcon>
						<ListItemText primary='Level Subscription Earning' />
					</SubItem>
					<SubItem button component={Link} to='/dashboard/team-revenue'>
						<ListItemIcon>
							<SubItemIcon className='sub-item-icon' />
							<SubItemArrow className='sub-item-arrow' />
						</ListItemIcon>
						<ListItemText primary='Team Revenue' />
					</SubItem>
					<SubItem button component={Link} to='/dashboard/boosting-income'>
						<ListItemIcon>
							<SubItemIcon className='sub-item-icon' />
							<SubItemArrow className='sub-item-arrow' />
						</ListItemIcon>
						<ListItemText primary='Boosting Income' />
					</SubItem>
					<SubItem button component={Link} to='/dashboard/royalty-bonus'>
						<ListItemIcon>
							<SubItemIcon className='sub-item-icon' />
							<SubItemArrow className='sub-item-arrow' />
						</ListItemIcon>
						<ListItemText primary='Royalty Bonus' />
					</SubItem>
					<SubItem button component={Link} to='/dashboard/pool-status'>
						<ListItemIcon>
							<SubItemIcon className='sub-item-icon' />
							<SubItemArrow className='sub-item-arrow' />
						</ListItemIcon>
						<ListItemText primary='Pool Status' />
					</SubItem>
					<SubItem button component={Link} to='/dashboard/reward'>
						<ListItemIcon>
							<SubItemIcon className='sub-item-icon' />
							<SubItemArrow className='sub-item-arrow' />
						</ListItemIcon>
						<ListItemText primary='Reward' />
					</SubItem>
					<SubItem button component={Link} to='/dashboard/member-total-report'>
						<ListItemIcon>
							<SubItemIcon className='sub-item-icon' />
							<SubItemArrow className='sub-item-arrow' />
						</ListItemIcon>
						<ListItemText primary='Member Total Report' />
					</SubItem>
					<SubItem button component={Link} to='/dashboard/trading-club-bonus'>
						<ListItemIcon>
							<SubItemIcon className='sub-item-icon' />
							<SubItemArrow className='sub-item-arrow' />
						</ListItemIcon>
						<ListItemText primary='Trading Club Bonus' />
					</SubItem>
				</List>
			</Collapse>

			{/* My Team Section */}
			<ListItem button onClick={() => handleClick('myTeam')}>
				<ListItemIcon>
					<People style={{ color: '#fff' }} />
				</ListItemIcon>
				<ListItemText primary='My Team' />
				{open.myTeam ? <ExpandLess /> : <ExpandMore />}
			</ListItem>
			<Collapse in={open.myTeam} timeout='auto' unmountOnExit>
				<List component='div' disablePadding>
					<SubItem button component={Link} to='/dashboard/team-downline'>
						<ListItemIcon>
							<SubItemIcon className='sub-item-icon' />
							<SubItemArrow className='sub-item-arrow' />
						</ListItemIcon>
						<ListItemText primary='Team Downline' />
					</SubItem>
					<SubItem button component={Link} to='/dashboard/my-direct'>
						<ListItemIcon>
							<SubItemIcon className='sub-item-icon' />
							<SubItemArrow className='sub-item-arrow' />
						</ListItemIcon>
						<ListItemText primary='My Direct' />
					</SubItem>
					<SubItem button component={Link} to='/dashboard/level-wise-report'>
						<ListItemIcon>
							<SubItemIcon className='sub-item-icon' />
							<SubItemArrow className='sub-item-arrow' />
						</ListItemIcon>
						<ListItemText primary='Level Wise Report' />
					</SubItem>
					<SubItem button component={Link} to='/dashboard/rank-achiever'>
						<ListItemIcon>
							<SubItemIcon className='sub-item-icon' />
							<SubItemArrow className='sub-item-arrow' />
						</ListItemIcon>
						<ListItemText primary='Rank Achiever' />
					</SubItem>
				</List>
			</Collapse>

			<ListItem button component={Link} to='/dashboard/support'>
				<ListItemIcon>
					<Settings style={{ color: '#fff' }} />
				</ListItemIcon>
				<ListItemText primary='Support' />
			</ListItem>

			{/* About Section */}
			<ListItem button onClick={() => handleClick('about')}>
				<ListItemIcon>
					<Support style={{ color: '#fff' }} />
				</ListItemIcon>
				<ListItemText primary='About' />
				{open.about ? <ExpandLess /> : <ExpandMore />}
			</ListItem>
			<Collapse in={open.about} timeout='auto' unmountOnExit>
				<List component='div' disablePadding>
					<SubItem button component={Link} to='/dashboard/business-plan'>
						<ListItemIcon>
							<SubItemIcon className='sub-item-icon' />
							<SubItemArrow className='sub-item-arrow' />
						</ListItemIcon>
						<ListItemText primary='Business Plan' />
					</SubItem>
					<SubItem button component={Link} to='/dashboard/news-events'>
						<ListItemIcon>
							<SubItemIcon className='sub-item-icon' />
							<SubItemArrow className='sub-item-arrow' />
						</ListItemIcon>
						<ListItemText primary='News & Events' />
					</SubItem>
				</List>
			</Collapse>

			<ListItem button onClick={handleLogout}>
				<ListItemIcon>
					<ExitToApp style={{ color: '#fff' }} />
				</ListItemIcon>
				<ListItemText primary='Log Out' />
			</ListItem>
		</List>
	);

	return (
		<>
			{isMobile && (
				<>
					<IconButton
						edge='start'
						color='inherit'
						aria-label='menu'
						onClick={toggleDrawer(true)}
						sx={{
							display: { xs: 'block', md: 'none' },
							position: 'fixed',
							top: '0',
							left: '0',
							margin: '0.5rem',
						}}>
						<Menu />
					</IconButton>
					<Drawer anchor='left' open={drawerOpen} onClose={toggleDrawer(false)}>
						<SidebarContainer>
							<SidebarHeader className='side-bar-header'>
								<img
									src='/images/logo.png'
									alt='Logo'
									style={{ width: '120px', marginRight: '1rem' }}
								/>
								<IconButton
									color='inherit'
									aria-label='close menu'
									onClick={toggleDrawer(false)}>
									<Close />
								</IconButton>
							</SidebarHeader>
							{menuItems}
						</SidebarContainer>
					</Drawer>
				</>
			)}
			{!isMobile && (
				<SidebarContainer>
					<SidebarHeader className='side-bar-header'>
						<img
							src='/images/logo.png'
							alt='Logo'
							style={{ width: '100%', height: '50px', marginRight: '1rem' }}
						/>
					</SidebarHeader>
					<Box
						display='flex'
						flexDirection='column'
						alignItems='center'
						p={1}
						borderRadius={1}
						color='white'>
						<Avatar
							src='/images/profile.png'
							alt='Profile'
							sx={{ width: 100, height: 100, mb: 1 }}
						/>
						<Box display='flex' flexDirection='column' alignItems='center'>
							<Typography variant='h6' component='div' align='center'>
								{profile?.name} {`[${profile?.userId}]`}
							</Typography>
							<Typography variant='body2' color='#bdc3c7' align='center'>
								{profile?.email}
							</Typography>
						</Box>
					</Box>
					{menuItems}
				</SidebarContainer>
			)}
		</>
	);
};

export default Sidebar;
