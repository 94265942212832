import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Grid, Box, Button, TextField, Divider } from '@mui/material';
import { styled } from '@mui/system';
import PrintIcon from '@mui/icons-material/Print';
import SaveAltIcon from '@mui/icons-material/SaveAlt';

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
	'& .MuiDataGrid-root': {
		background:'linear-gradient(81deg, #0f243e 0%, #4fb1e0 100%)',
		color: '#fff',
	},
	'& .MuiDataGrid-cell': {
		borderBottom: '1px solid #fff',
		color: '#fff',
	},
	'& .MuiDataGrid-columnHeaders': {
		background: 'linear-gradient(81deg, #0f243e 0%, #4fb1e0 100%)',
		borderBottom: '1px solid #fff',
	},
	'& .MuiDataGrid-columnHeaderTitle': {
		fontWeight: 'bold',
	},
	'& .MuiDataGrid-footerContainer': {
		background: 'linear-gradient(81deg, #0f243e 0%, #4fb1e0 100%)',
		color: '#fff',
	},
	'& .MuiTablePagination-root': {
		color: '#fff',
	},
	'& .MuiSvgIcon-root': {
		// color: '#fff',
	},
	'& .MuiButtonBase-root': {
		// color: '#fff',
	},
	'& .MuiCheckbox-root svg': {
		fill: '#fff',
	},
	'& .MuiDataGrid-row': {
		background:'linear-gradient(81deg, #0f243e 0%, #4fb1e0 100%)',
		'&:nth-of-type(odd)': {
			backgroundColor: '#1c3b30',
		},
		color: '#fff',
	},
	'& .MuiDataGrid-iconButtonContainer': {
		visibility: 'visible',
		color: '#fff',
	},
	'& .MuiDataGrid-menuIcon': {
		visibility: 'visible',
		width: 'auto',
	},
}));

const inputCustomStyle = {
	background:'linear-gradient(81deg, #0f243e 0%, #4fb1e0 100%)',
	borderRadius: 1,
	'& .MuiInputBase-root': {
		color: '#fff',
	},
	'& .MuiOutlinedInput-notchedOutline': {
		borderColor: '#fff',
	},
	'& .MuiInputLabel-root': {
		color: '#fff',
	},
};

const buttonCustomStyle = {
	marginRight: 2,
	backgroundColor: '#007bff',
	color: '#fff',
	'&:hover': {
		backgroundColor: '#0056b3',
	},
};

const DynamicGrid = ({
	columns,
	rows,
	showSearch = true,
	excelButton = { active: true, text: 'Export' },
	printButton = { active: true, text: 'Print' },
}) => {
	const [pageSize, setPageSize] = useState(10);
	const [searchText, setSearchText] = useState('');
	const [filteredRows, setFilteredRows] = useState(rows);

	const handleGlobalSearch = (event) => {
		const value = event.target.value.toLowerCase();
		setSearchText(value);
		setFilteredRows(
			rows.filter((row) => {
				return Object.values(row).some((field) =>
					field?.toString().toLowerCase().includes(value)
				);
			})
		);
	};

	const handlePrint = () => {
		window.print();
	};

	return (
		<Box>
			<Grid
				container
				spacing={2}
				alignItems='center'
				justifyContent='space-between'
				wrap='wrap'>
				<Grid item>
					{printButton.active && (
						<Button
							variant='contained'
							onClick={handlePrint}
							sx={buttonCustomStyle}
							startIcon={<PrintIcon />}>
							{printButton.text}
						</Button>
					)}
					{excelButton.active && (
						<Button
							variant='contained'
							sx={buttonCustomStyle}
							startIcon={<SaveAltIcon />}>
							{excelButton.text}
						</Button>
					)}
				</Grid>

				{showSearch && (
					<Grid item xs={12} sm={6} md={4}>
						<TextField
							label='Search'
							variant='outlined'
							value={searchText}
							onChange={handleGlobalSearch}
							size='small'
							sx={inputCustomStyle}
							fullWidth
						/>
					</Grid>
				)}
			</Grid>
			<Box
				mt={2}
				sx={{
					height: '70%',
					background:'linear-gradient(81deg, #0f243e 0%, #4fb1e0 100%)',
					borderRadius: 8,
					overflowX: 'auto',
				}}>
				<StyledDataGrid
					columns={columns}
					rows={filteredRows}
					pagination
					pageSize={pageSize}
					onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
					disableSelectionOnClick
					autoHeight
					initialState={{
						pagination: { paginationModel: { pageSize: 10 } },
					}}
					pageSizeOptions={[5, 10, 25, 50]}
				/>
			</Box>
		</Box>
	);
};

export default DynamicGrid;
