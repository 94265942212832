import React from 'react';
import styled from 'styled-components';
import aboutUsImage from '../assets/images/about1.png';

const Section = styled.section`
	padding: 2rem 1rem;
	background-color: #f5f5f5;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;

	@media (min-width: 768px) {
		padding: 3rem 2rem;
	}
`;

const Title = styled.h2`
	font-size: 2rem;
	color: #333;
	margin-bottom: 1rem;

	@media (min-width: 768px) {
		font-size: 2.5rem;
	}
`;

const Container = styled.div`
	width: 100%;
	max-width: 60%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	@media (min-width: 768px) {
		flex-direction: row;
	}
`;

const Spacer = styled.div`
	flex: 1;
`;

const TextContent = styled.div`
	flex: 1;
	text-align: left;

	@media (min-width: 768px) {
		text-align: left;
	}
`;

const Subtitle = styled.h3`
	font-size: 1.5rem;
	color: #333;
	margin-bottom: 0.5rem;
`;

const Highlight = styled.div`
	width: 50px;
	height: 5px;
	background-color: #f39c12;
	margin: 0.5rem 0 1rem 0;
`;

const Text = styled.p`
	font-size: 1rem;
	color: #555;
	line-height: 1.6;
	margin-bottom: 1rem;
`;

const AboutUsImg = styled.img`
	width: 50%;
	height: 50%;
	border-radius: 8px;
	object-fit: cover;
	margin-bottom: 20px;
	margin-right: 20px;

	@media (max-width: 768px) {
		width: 100%;
		height: 100%;
		margin-right: 0px;
	}
`;

const AboutUsSection = () => {
	return (
		<Section id='about-us'>
			<Container>
				<AboutUsImg src={aboutUsImage} alt={'About Us'} />
				<TextContent>
					<Title>ABOUT US</Title>
					<Subtitle>Welcome to The Crowd World Community</Subtitle>
					<Highlight />
					<Text>Where a person helps another person</Text>
					<Text>
						The Digital world started from a small town in the Netherlands, where it
						was created as a support system to help the local people. Which was
						later named Digital Word Support System, people were very impressed
						by it.
					</Text>
					<Text>
						Later it was seen that people donate, they also have some
						expectations, so along with donation, a reward program was also
						started. But it was not at that time or on a very large scale. In
						2019, when the whole world was in the grip of the Corona virus
						globally, it is obvious that our country Netherlands was also badly
						affected by it, at that time did a lot through this donation.
					</Text>
					<Text>
						Helped all the people, being influenced by it, further helped the
						person who helped, in this way a chain went ahead and people were
						very impressed by it, now it works as a crowdfund P to P support
						system, where a person helps another person.
					</Text>
				</TextContent>
			</Container>
		</Section>
	);
};

export default AboutUsSection;
