import React from 'react';
import {
	Box,
	TextField,
	Button,
	Typography,
	InputAdornment,
} from '@mui/material';
import { AccountCircle, Email, Phone, Message } from '@mui/icons-material';
import { styled } from '@mui/system';

const FormContainer = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	padding: theme.spacing(4),
	backgroundColor: '#f5f5f5',
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
	margin: theme.spacing(1, 0),
	width: '100%',
	'& .MuiInputBase-root': {
		borderRadius: 4,
		'& fieldset': {
			borderColor: '#ddd',
		},
	},
}));

const StyledButton = styled(Button)(({ theme }) => ({
	marginTop: theme.spacing(2),
	backgroundColor: '#d4a217',
	color: '#fff',
	'&:hover': {
		backgroundColor: '#c39a14',
	},
	width: '100%',
	maxWidth: 300,
}));

const ContactForm = () => {
	return (
		<FormContainer id='contact'>
			<Typography variant='h5' gutterBottom>
				CONTACT
			</Typography>
			<Typography variant='body1' gutterBottom>
				The Crowd World Community is always ready at your service 24/7 hours.
			</Typography>
			<Box
				component='form'
				noValidate
				autoComplete='off'
				sx={{ width: '100%', maxWidth: 600 }}>
				<StyledTextField
					variant='outlined'
					label='Name'
					placeholder='Name'
					InputProps={{
						startAdornment: (
							<InputAdornment position='start'>
								<AccountCircle />
							</InputAdornment>
						),
					}}
				/>
				<StyledTextField
					variant='outlined'
					label='Email address *'
					placeholder='Email address'
					InputProps={{
						startAdornment: (
							<InputAdornment position='start'>
								<Email />
							</InputAdornment>
						),
					}}
				/>
				<StyledTextField
					variant='outlined'
					label='Phone number'
					placeholder='Phone number'
					InputProps={{
						startAdornment: (
							<InputAdornment position='start'>
								<Phone />
							</InputAdornment>
						),
					}}
				/>
				<StyledTextField
					variant='outlined'
					label='Write message *'
					placeholder='Write message'
					multiline
					rows={4}
					InputProps={{
						startAdornment: (
							<InputAdornment position='start'>
								<Message />
							</InputAdornment>
						),
					}}
				/>
				<StyledButton variant='contained'>SEND MESSAGE</StyledButton>
			</Box>
		</FormContainer>
	);
};

export default ContactForm;
