// utils/dummyData.js
export const generateDummyData = (numRows) => {
	const dummyData = [];
	const names = [
		'John',
		'Jane',
		'Michael',
		'Sarah',
		'David',
		'Emily',
		'Daniel',
		'Emma',
		'James',
		'Olivia',
	];
	const usernames = [
		'john_doe',
		'jane_doe',
		'michael_123',
		'sarah_smith',
		'david_jones',
		'emily_clark',
		'daniel_wilson',
		'emma_davis',
		'james_brown',
		'olivia_johnson',
	];

	for (let i = 1; i <= numRows; i++) {
		const name = names[Math.floor(Math.random() * names.length)];
		const username = usernames[Math.floor(Math.random() * usernames.length)];
		const sponsorName = 'Shivam tiwari';
		const sponsorId = 'DB547716';
		const activationDate = i % 10 === 0 ? new Date().toLocaleString() : '-';
		const amount = i % 10 === 0 ? 20 : 0;

		dummyData.push({
			id: i,
			name: `${name} ${i}`,
			userId: `DB${Math.floor(100000 + Math.random() * 900000)}`,
			username: `${username}${i}`,
			sponsorName,
			sponsorId,
			activationDate,
			amount,
		});
	}

	return dummyData;
};

export const DATE_FORMATS = {
	DISPLAY_FORMAT: 'dd-MMMM-yyyy',
	API_FORMAT: 'yyyy-MM-dd',
};

export const setTokenInCookies = (token) => {
	const expires = new Date();
	expires.setTime(expires.getTime() + 24 * 60 * 60 * 1000);
	document.cookie = `token=${token}; expires=${expires.toUTCString()}; path=/; Secure`;
};
