import React, { useState } from 'react';
import { Container, Box, TextField, Button, Typography } from '@mui/material';
import { Person } from '@mui/icons-material';
import styled from 'styled-components';

const FormWrapper = styled(Box)`
	background: #fff;
	padding: 2rem;
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	max-width: 500px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	background: linear-gradient(180deg, #e0e0e0, #f0f0f0);
`;

const ImageWrapper = styled(Box)`
	width: 100%;
	display: flex;
	justify-content: center;
	margin-bottom: 1rem;
`;

const FormTitle = styled(Typography)`
	color: #2c3e50;
	margin-bottom: 1rem;
`;

const ForgotPassword = () => {
	const [username, setUsername] = useState('');

	const handleSubmit = (event) => {
		event.preventDefault();
		// Handle password reset logic here
		console.log('Username:', username);
	};

	return (
		<Container
			sx={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				height: '100vh',
				backgroundColor: '#f9f9f9',
			}}>
			<FormWrapper>
				<ImageWrapper>
					<img
						src='/assets/images/logo.png'
						alt='Logo'
						style={{ width: '50%', borderRadius: '8px' }}
					/>
				</ImageWrapper>
				<FormTitle variant='h4'>Forget Password</FormTitle>
				<Typography
					variant='body1'
					sx={{ marginBottom: '1rem', textAlign: 'center' }}>
					Enter your UserName and we will send your reset password to the email
					registered with us.
				</Typography>
				<form onSubmit={handleSubmit}>
					<TextField
						fullWidth
						variant='outlined'
						margin='normal'
						placeholder='Username'
						label='Username'
						required
						InputProps={{
							startAdornment: (
								<Person sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
							),
						}}
						value={username}
						onChange={(e) => setUsername(e.target.value)}
					/>
					<Button
						type='submit'
						variant='contained'
						color='primary'
						fullWidth
						sx={{
							padding: '0.75rem',
							marginTop: '1rem',
							background: 'linear-gradient(to right, #007BFF, #0056b3)',
						}}>
						Send Password
					</Button>
					<Typography
						variant='body2'
						sx={{ marginTop: '1rem', textAlign: 'center' }}>
						OR
					</Typography>
					<Typography
						variant='body2'
						sx={{ marginTop: '0.5rem', textAlign: 'center' }}>
						<a
							href='/login'
							style={{ color: '#007BFF', textDecoration: 'none' }}>
							&larr; Back to Login
						</a>
					</Typography>
				</form>
			</FormWrapper>
			<Box sx={{ marginTop: '2rem', textAlign: 'center' }}>
				<img
					src='/assets/images/forgot_password_image.png'
					alt='Welcome'
					style={{ width: '60%', borderRadius: '8px' }}
				/>
				<Typography variant='h5' sx={{ marginTop: '1rem', color: '#2c3e50' }}>
					Welcome to The Digital World opportunity
				</Typography>
			</Box>
		</Container>
	);
};

export default ForgotPassword;
