import React from 'react';
import { Box, Grid, TextField, MenuItem, Button, Typography } from '@mui/material';
import { styled } from '@mui/system';

const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: '#294e46',
  color: '#fff',
  borderRadius: theme.shape.borderRadius,
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1),
  },
}));

const UploadBox = styled(Box)(({ theme }) => ({
  border: '1px dashed #fff',
  borderRadius: theme.shape.borderRadius,
  height: '150px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#fff',
  marginBottom: theme.spacing(1),
}));

const KYCForm = () => {
  return (
    <Container>
      <Typography variant="h6" gutterBottom>KYC Details</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            select
            label="Photo Id"
            fullWidth
            variant="outlined"
            SelectProps={{
              native: true,
            }}
            InputLabelProps={{
              style: { color: '#fff' },
            }}
            InputProps={{
              style: { color: '#fff' },
            }}
          >
            <option value="taxId">TAX Id</option>
          </TextField>
          <TextField
            label="Enter TAX Id"
            fullWidth
            variant="outlined"
            margin="normal"
            InputLabelProps={{
              style: { color: '#fff' },
            }}
            InputProps={{
              style: { color: '#fff' },
            }}
          />
          <UploadBox>
            <Typography>Image Not Available</Typography>
          </UploadBox>
          <Button variant="contained" color="primary">Upload Image</Button>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            select
            label="Address Id"
            fullWidth
            variant="outlined"
            SelectProps={{
              native: true,
            }}
            InputLabelProps={{
              style: { color: '#fff' },
            }}
            InputProps={{
              style: { color: '#fff' },
            }}
          >
            <option value="mobileInternetBill">Mobile Internet Bill</option>
          </TextField>
          <TextField
            label="Enter Document Number"
            fullWidth
            variant="outlined"
            margin="normal"
            InputLabelProps={{
              style: { color: '#fff' },
            }}
            InputProps={{
              style: { color: '#fff' },
            }}
          />
          <UploadBox>
            <Typography>Image Not Available</Typography>
          </UploadBox>
          <Button variant="contained" color="primary">Upload Image</Button>
        </Grid>
      </Grid>
      <Box mt={2}>
        <Typography variant="h6">Document List</Typography>
        <Grid container spacing={1}>
          <Grid item xs={2}>
            <Typography variant="body1">S.No.</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body1">Document Name</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body1">Document Number</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body1">Document Photo</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body1">Status</Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="body1">Admin Remark</Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="body1">A/R Date</Typography>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default KYCForm;
