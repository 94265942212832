import React from 'react';
import { Box, Grid, TextField, Button, Typography } from '@mui/material';
import { styled } from '@mui/system';

const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: '#294e46',
  color: '#fff',
  borderRadius: theme.shape.borderRadius,
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1),
  },
}));

const HistoryBox = styled(Box)(({ theme }) => ({
  border: '1px solid #fff',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  color: '#fff',
}));

const RetopupIDHistory = () => {
  return (
    <Container>
      <Typography variant="h6" gutterBottom>Your ReTopUp  History</Typography>
      <HistoryBox>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="From Date"
              type="date"
              fullWidth
              variant="outlined"
              margin="normal"
              InputLabelProps={{
                shrink: true,
                style: { color: '#fff' },
              }}
              InputProps={{
                style: { color: '#fff' },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="To Date"
              type="date"
              fullWidth
              variant="outlined"
              margin="normal"
              InputLabelProps={{
                shrink: true,
                style: { color: '#fff' },
              }}
              InputProps={{
                style: { color: '#fff' },
              }}
            />
          </Grid>
        </Grid>
        <Button variant="contained" color="primary" fullWidth>Search</Button>
      </HistoryBox>
    </Container>
  );
};

export default RetopupIDHistory;
