import React from 'react';
import styled from 'styled-components';

const Section = styled.section`
	padding: 2rem 1rem;
	background-color: #fff;
	text-align: center;

	@media (min-width: 768px) {
		padding: 3rem 2rem;
	}
`;

const Title = styled.h2`
	font-size: 2rem;
	color: #333;
	margin-bottom: 1rem;

	@media (min-width: 768px) {
		font-size: 2.5rem;
	}
`;

const ValuesContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 2rem;
	margin: 2rem 0;

	@media (min-width: 768px) {
		flex-direction: row;
		justify-content: space-around;
		flex-wrap: wrap; /* Ensure wrapping on small screens */
	}
`;

const ValueCard = styled.div`
	display: flex;
	align-items: flex-start;
	text-align: left;
	max-width: 300px;

	@media (min-width: 768px) {
		max-width: none;
		width: 30%;
	}
`;

const Icon = styled.img`
	width: 50px;
	height: 50px;
	margin-right: 1rem;
`;

const Content = styled.div`
	flex: 1;
`;

const ValueTitle = styled.h3`
	font-size: 1.5rem;
	color: #333;
`;

const ValueText = styled.p`
	font-size: 1rem;
	color: #555;
	line-height: 1.5;
`;

const values = [
	{
		title: 'Collaboration',
		text: 'The Digital World Community emphasizes working together and sharing knowledge to achieve common goals. Members are encouraged to collaborate with each other to solve problems and create new opportunities.',
		iconUrl: '/images/partners.png', // replace with the actual path
	},
	{
		title: 'Innovation',
		text: 'The Digital World Community values creativity and forward-thinking, and seeks to foster a culture of innovation among its members. This includes experimenting with new technologies and exploring new ways of doing things.',
		iconUrl: '/images/project-management.png', // replace with the actual path
	},
	{
		title: 'Inclusivity',
		text: 'The Digital World Community is open to people from all backgrounds and encourages diversity in its membership. The organization is committed to creating a welcoming environment where everyone can feel valued and respected.',
		iconUrl: '/images/teamwork.png', // replace with the actual path
	},
];

const ValuesSection = () => {
	return (
		<Section>
			<Title>Our Values</Title>
			<ValuesContainer>
				{values.map((value, index) => (
					<ValueCard key={index}>
						<Icon src={value.iconUrl} alt={`${value.title} icon`} />
						<Content>
							<ValueTitle>{value.title}</ValueTitle>
							<ValueText>{value.text}</ValueText>
						</Content>
					</ValueCard>
				))}
			</ValuesContainer>
		</Section>
	);
};

export default ValuesSection;
