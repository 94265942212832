import React, { useState } from 'react';

function PremiereSupply() {

  const [dwctAmounts, setDwctAmounts] = useState([0, 0, 0, 0, 0]);

  const handleDwctChange = (index, value) => {
    const updatedDwctAmounts = [...dwctAmounts];
    updatedDwctAmounts[index] = value;
    setDwctAmounts(updatedDwctAmounts);
  };

  const handleClaim = (index) => {
    // Handle claim logic here
    console.log(`Claiming for round ${index + 1}`);
  };

  return (
    <div className="container">
      <h1>Premiere Supply</h1>
      <div className="buttons">
        <button className="fund-wallet">Fund Wallet | $0</button>
        <button className="dwct-coin">DWCT Coin | 30</button>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th>S.No.</th>
            <th>Round</th>
            <th>Prize</th>
            <th>Date & Time</th>
            <th>DWCT</th>
            <th>Amount USDT</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>1 st Round [1%] Seed Farming</td>
            <td>0.02</td>
            <td></td>
            <td>
              <input
                type="number"
                min="0"
                value={dwctAmounts[0]}
                onChange={(e) => handleDwctChange(0, e.target.value)}
              />
            </td>
            <td></td>
            <td>
              <button onClick={() => handleClaim(0)}>Claim</button>
            </td>
          </tr>
          <tr>
            <td>2</td>
            <td>2 nd Round [14%] Core Member And Member</td>
            <td>0.03</td>
            <td></td>
            <td>
              <input
                type="number"
                min="0"
                value={dwctAmounts[1]}
                onChange={(e) => handleDwctChange(1, e.target.value)}
              />
            </td>
            <td></td>
            <td>
              <button onClick={() => handleClaim(1)}>Claim</button>
            </td>
          </tr>
          <tr>
            <td>3</td>
            <td>3rd Round [20%] All Member</td>
            <td>0.04</td>
            <td></td>
            <td>
              <input
                type="number"
                min="0"
                value={dwctAmounts[2]}
                onChange={(e) => handleDwctChange(2, e.target.value)}
              />
            </td>
            <td></td>
            <td>
              <button onClick={() => handleClaim(2)}>Claim</button>
            </td>
          </tr>
          <tr>
            <td>4</td>
            <td>4th Round [25%] All Community</td>
            <td>0.05</td>
            <td>26.10.23 11:30 AM (UK Time Zon</td>
            <td>
              <input
                type="number"
                min="0"
                value={dwctAmounts[3]}
                onChange={(e) => handleDwctChange(3, e.target.value)}
              />
            </td>
            <td></td>
            <td>
              <button onClick={() => handleClaim(3)}>Claim</button>
            </td>
          </tr>
          <tr>
            <td>5</td>
            <td>5th Round [40%] Open Community</td>
            <td>0.1144</td>
            <td>Live open Price</td>
            <td>
              <input
                type="number"
                min="0"
                value={dwctAmounts[4]}
                onChange={(e) => handleDwctChange(4, e.target.value)}
              />
            </td>
            <td></td>
            <td>
              <button onClick={() => handleClaim(4)}>Claim</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );

}

export default PremiereSupply;
