import React from 'react';
import GridPage from '../GridPage';

const SponsorTeam = () => {
	return (
		<GridPage
			pageName='DirectTeam'
			title='My Sponsor'
			requiredSection={{
				globalFilter: true,
				levelFilter: false,
				status: true,
			}}
		/>
	);
};

export default SponsorTeam;
