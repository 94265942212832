import React, { useState } from 'react';
import GridPage from '../GridPage';

const LevelWiseTeamList = () => {
	return (
		<>
			<GridPage
				pageName='LevelTeamGenealogy'
				title='My Level Wise Team List'
				requiredSection={{
					globalFilter: false,
					levelFilter: true,
					status: true,
				}}
			/>
		</>
	);
};

export default LevelWiseTeamList;
