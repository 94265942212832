import React from 'react';
import styled from 'styled-components';

const Section = styled.section`
	padding: 2rem 1rem;
	background-color: #f9f9f9;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 1rem;
`;

const Title = styled.h2`
	font-size: 1.5rem;
	color: #333;
`;

const JoinButton = styled.button`
	background: none;
	border: 1px solid #333;
	padding: 0.5rem 1rem;
	font-size: 1rem;
	cursor: pointer;
	transition: background 0.3s ease, color 0.3s ease;

	&:hover {
		background: #333;
		color: #fff;
	}
`;

const JoinSection = () => {
	return (
		<Section>
			<Title>Join Our Meta Community</Title>
			<JoinButton>Join Us</JoinButton>
		</Section>
	);
};

export default JoinSection;
