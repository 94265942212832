import React from 'react';
import { Box, Typography, Paper } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/AuthContext';
import CryptoJS from 'crypto-js';

const CardContainer = styled(Paper)(({ theme, link }) => ({
	background: 'linear-gradient(81deg, #0f243e 0%, #4fb1e0 100%)', // Gradient background
	borderRadius: '8px',
	padding: '0.8rem',
	boxSizing: 'border-box',
	boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-between',
	height: '100%',
	transition: 'transform 0.2s',
	'&:hover': {
		transform: 'scale(1.05)',
	},
	cursor: link ? 'pointer' : 'default',
	userSelect: 'none',
}));

const HeaderContainer = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	width: '100%',
}));

const CardIcon = styled(Box)(({ theme }) => ({
	color: '#ffffff',
}));

const DashboardCard = ({
	title,
	value,
	icon: Icon,
	footer,
	link,
	isCopyLink,
}) => {
	const navigate = useNavigate();
	const { profile } = useAuth();

	const encryptUsername = (username) => {
		return CryptoJS.AES.encrypt(username, 'Usr@#$123').toString();
	};

	const generateReferralLink = (currentUserId, username) => {
		const encryptedUsername = encryptUsername(username);
		return `https://thedigitalworlds.com/signup?sponsorId=${currentUserId}&username=${encodeURIComponent(
			encryptedUsername
		)}`;
	};
	const handleClick = (link) => {
		if (isCopyLink) {
			const referralLink = generateReferralLink(
				profile?.userId,
				profile?.username
			);
			navigator.clipboard.writeText(referralLink).then(() => {
				alert('Referral link copied to clipboard');
			});
		} else if (link === '/signup') {
			const encryptedUsername = encryptUsername(profile?.username);
			navigate(
				`/signup?sponsorId=${profile?.userId}&username=${encryptedUsername}`
			);
		} else {
			navigate(link);
		}
	};

	return (
		<CardContainer onClick={() => handleClick(link)}>
			<HeaderContainer>
				<Typography variant='body1' style={{ fontSize: 14, color: '#ffffff' }}>
					{title}
				</Typography>
				<CardIcon>
					<Icon style={{ fontSize: 20, color: '#ffffff' }} />
				</CardIcon>
			</HeaderContainer>
			<Box
				display='flex'
				sx={{ padding: '24px' }}
				flexDirection='column'
				alignItems='center'>
				<Typography
					variant='h5'
					style={{ fontSize: 26, color: '#ffffff', fontWeight: 'bold' }}>
					{value}
				</Typography>

				<Typography variant='body2' style={{ color: '#b2dfdb' }}>
					{footer || '___'}
				</Typography>
			</Box>
		</CardContainer>
	);
};

export default DashboardCard;
