import React, { useState } from 'react';
import GridPage from '../GridPage';

const ReferralBonus = () => {
	return (
		<>
			<GridPage
				pageName='DirectIncomeList'
				title='Direct Income , Income from your direct Id'
				requiredSection={{
					globalFilter: true,
					levelFilter: false,
					status: false,
				}}
			/>
		</>
	);
};

export default ReferralBonus;
