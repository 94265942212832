import React, { useState, useEffect } from 'react';
import {
    TextField,
    Button,
    MenuItem,
    Grid,
    Paper,
    Typography,
    Avatar,
    FormControl,
    InputLabel,
    Select,
    Alert
} from '@mui/material';
import { styled } from '@mui/system';

const genders = [
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' },
    { value: 'Other', label: 'Other' },
];

const maritalStatuses = [
    { value: 'Unmarried', label: 'Unmarried' },
    { value: 'Married', label: 'Married' },
    { value: 'Divorced', label: 'Divorced' },
    { value: 'Widowed', label: 'Widowed' },
];

const countries = [
    { value: 'India', label: 'India' },
    { value: 'USA', label: 'USA' },
    { value: 'UK', label: 'UK' },
];

const states = [
    { value: 'उत्तर प्रदेश', label: 'उत्तर प्रदेश' },
    { value: 'महाराष्ट्र', label: 'महाराष्ट्र' },
    { value: 'गुजरात', label: 'गुजरात' },
];

const cities = [
    { value: 'Bahraich', label: 'Bahraich' },
    { value: 'Mumbai', label: 'Mumbai' },
    { value: 'Ahmedabad', label: 'Ahmedabad' },
];

const FormPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    backgroundColor: '#2f4f4f',
    color: 'white',
}));

const UpdateProfile = () => {
    const [profile, setProfile] = useState({
        title: 'Mr.',
        firstName: '',
        mobileNo: '',
        gender: 'Male',
        maritalStatus: 'Unmarried',
        country: 'India',
        pinCode: '',
        address: '',
        otp: '',
        dateOfBirth: '',
        fatherName: '',
        emailId: '',
        houseTelephone: '',
        state: '',
        city: ''
    });
    const [initialProfile, setInitialProfile] = useState(null);
    const [error, setError] = useState('');
    const [isProfileChanged, setIsProfileChanged] = useState(false);

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const response = await fetch('http://api.thedigitalworlds.com/api/v1/me', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch profile data');
                }

                const data = await response.json();
                setProfile(data);
                setInitialProfile(data);
            } catch (err) {
                setError(err.message);
            }
        };

        fetchProfile();
    }, []);

    useEffect(() => {
        if (initialProfile) {
            const isChanged = Object.keys(profile).some(
                key => profile[key] !== initialProfile[key]
            );
            setIsProfileChanged(isChanged);
        }
    }, [profile, initialProfile]);

    const handleChange = (e) => {
        setProfile({ ...profile, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('http://api.thedigitalworlds.com/api/v1/me', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(profile),
            });

            if (!response.ok) {
                throw new Error('Failed to update profile');
            }

            const data = await response.json();
            if (data.success) {
                setInitialProfile(profile);
                setError('');
            } else {
                setError('Failed to update profile');
            }
        } catch (err) {
            setError(err.message);
        }
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
                <FormPaper sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap:'10px'
                }}>
                    <Avatar alt="Profile Picture" src="#" sx={{ width: 100, height: 100, margin: 'auto' }} />
                    <Button variant="contained" component="label" sx={{ mt: 2, backgroundColor: '#006400', margin: 'auto' }}>
                        Upload Profile
                        <input type="file" hidden />
                    </Button>
                </FormPaper>
            </Grid>
            <Grid item xs={12} md={8}>
                <FormPaper component="form" onSubmit={handleSubmit}>
                    <Typography variant="h6" gutterBottom>
                        Personal Info
                    </Typography>
                    {error && (
                        <Alert severity='error' sx={{ width: '100%', mb: 2 }}>
                            {error}
                        </Alert>
                    )}
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel style={{ color: 'white' }}>Title</InputLabel>
                                <Select
                                    name="title"
                                    value={profile.title}
                                    onChange={handleChange}
                                    label="Title"
                                    style={{ color: 'white' }}
                                    inputProps={{
                                        style: { color: 'white' }
                                    }}
                                >
                                    <MenuItem value="Mr.">Mr.</MenuItem>
                                    <MenuItem value="Mrs.">Mrs.</MenuItem>
                                    <MenuItem value="Ms.">Ms.</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="First Name"
                                name="firstName"
                                value={profile.firstName}
                                onChange={handleChange}
                                InputLabelProps={{
                                    style: { color: 'white' },
                                }}
                                InputProps={{
                                    style: { color: 'white' },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Mobile No"
                                name="mobileNo"
                                value={profile.mobileNo}
                                onChange={handleChange}
                                InputLabelProps={{
                                    style: { color: 'white' },
                                }}
                                InputProps={{
                                    style: { color: 'white' },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel style={{ color: 'white' }}>Gender</InputLabel>
                                <Select
                                    name="gender"
                                    value={profile.gender}
                                    onChange={handleChange}
                                    label="Gender"
                                    style={{ color: 'white' }}
                                    inputProps={{
                                        style: { color: 'white' }
                                    }}
                                >
                                    {genders.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel style={{ color: 'white' }}>Marital Status</InputLabel>
                                <Select
                                    name="maritalStatus"
                                    value={profile.maritalStatus}
                                    onChange={handleChange}
                                    label="Marital Status"
                                    style={{ color: 'white' }}
                                    inputProps={{
                                        style: { color: 'white' }
                                    }}
                                >
                                    {maritalStatuses.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel style={{ color: 'white' }}>Country</InputLabel>
                                <Select
                                    name="country"
                                    value={profile.country}
                                    onChange={handleChange}
                                    label="Country"
                                    style={{ color: 'white' }}
                                    inputProps={{
                                        style: { color: 'white' }
                                    }}
                                >
                                    {countries.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Pin Code"
                                name="pinCode"
                                value={profile.pinCode}
                                onChange={handleChange}
                                InputLabelProps={{
                                    style: { color: 'white' },
                                }}
                                InputProps={{
                                    style: { color: 'white' },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Address"
                                name="address"
                                value={profile.address}
                                onChange={handleChange}
                                InputLabelProps={{
                                    style: { color: 'white' },
                                }}
                                InputProps={{
                                    style: { color: 'white' },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="OTP"
                                name="otp"
                                value={profile.otp}
                                onChange={handleChange}
                                InputLabelProps={{
                                    style: { color: 'white' },
                                }}
                                InputProps={{
                                    style: { color: 'white' },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Date of Birth"
                                name="dateOfBirth"
                                value={profile.dateOfBirth}
                                onChange={handleChange}
                                InputLabelProps={{
                                    style: { color: 'white' },
                                }}
                                InputProps={{
                                    style: { color: 'white' },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Father's Name"
                                name="fatherName"
                                value={profile.fatherName}
                                onChange={handleChange}
                                InputLabelProps={{
                                    style: { color: 'white' },
                                }}
                                InputProps={{
                                    style: { color: 'white' },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Email ID"
                                name="emailId"
                                value={profile.emailId}
                                onChange={handleChange}
                                InputLabelProps={{
                                    style: { color: 'white' },
                                }}
                                InputProps={{
                                    style: { color: 'white' },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="House Telephone"
                                name="houseTelephone"
                                value={profile.houseTelephone}
                                onChange={handleChange}
                                InputLabelProps={{
                                    style: { color: 'white' },
                                }}
                                InputProps={{
                                    style: { color: 'white' },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel style={{ color: 'white' }}>State</InputLabel>
                                <Select
                                    name="state"
                                    value={profile.state}
                                    onChange={handleChange}
                                    label="State"
                                    style={{ color: 'white' }}
                                    inputProps={{
                                        style: { color: 'white' }
                                    }}
                                >
                                    {states.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel style={{ color: 'white' }}>City</InputLabel>
                                <Select
                                    name="city"
                                    value={profile.city}
                                    onChange={handleChange}
                                    label="City"
                                    style={{ color: 'white' }}
                                    inputProps={{
                                        style: { color: 'white' }
                                    }}
                                >
                                    {cities.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        variant="contained"
                        sx={{ mt: 3, backgroundColor: '#006400' }}
                        disabled={!isProfileChanged}
                    >
                        Update Profile
                    </Button>
                </FormPaper>
            </Grid>
        </Grid>
    );
};

export default UpdateProfile;
