import React, { useState } from 'react';
import GridPage from '../GridPage';

const DepositHistory = () => {
	return (
		<>
			<GridPage
				pageName='RequestFundHistory'
				title='Your Deposit History'
				requiredSection={{
					globalFilter: true,
					levelFilter: false,
					status: false,
				}}
			/>
		</>
	);
};

export default DepositHistory;
