const activities = [
	{
		title: 'Crowd funding',
		subtitle: 'P2P Donation & reward program',
		text: 'Members of our community support each other through Donation & reward Program via P2P. This sequence continues indefinitely, fostering mutual fundraising efforts such as Auto pool, rewards, equity, etc.',
		imageUrl: '/images/crowd_funding.jpg',
	},
	{
		title: 'Welfare',
		subtitle: 'Help our members with their needs',
		text: "Community members assist each other in times of need. According to our unity rule, 1% of earnings through any means is contributed to the community's welfare fund, which is used for community welfare purposes.",
		imageUrl: '/images/welfare.jpg',
	},
	{
		title: 'Gaming & Esports',
		subtitle: 'Enjoy your Moment',
		text: 'Raise money by playing various games on this platform and enjoy your leisure time. Earn money through gaming.',
		imageUrl: '/images/gaming_esports.jpg',
	},
	{
		title: 'Trading Bot',
		subtitle: 'Algorithms your trading strategies',
		text: 'Trade on crypto exchanges with state-of-the-art trading bots, earning profit based on algorithmic analysis.',
		imageUrl: '/images/Trading_Bot.jpg',
	},
	{
		title: 'E-commerce',
		subtitle: 'Meet your needs',
		text: 'Buy daily needs items from our e-commerce site, and exchange various services. Use different types of cryptocurrency for purchases.',
		imageUrl: '/images/E-commerce.jpg',
	},
	{
		title: 'Crypto Exchange',
		subtitle: 'Buy, Sell & Trade crypto coins',
		text: 'Convert, exchange, stake, trade cryptocurrencies, and receive your cryptocurrency in your own currency. A fully decentralized program.',
		imageUrl: '/images/exchange.jpg',
	},
	{
		title: 'Utility Services',
		subtitle: 'Essential to everyday life',
		text: 'Essential services provided by public or private organizations such as electricity, gas, water, and insurance.',
		imageUrl: '/images/utility.jpg',
	},
	{
		title: 'Community metaverce',
		subtitle: 'Virtual world for socializing, commerce, and cooperation',
		text: 'A virtual world where people interact, collaborate, and create together, transcending physical boundaries and fostering a sense of community.',
		imageUrl: '/images/metaverce.jpg',
	},
];

export default activities;
