import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import DashboardCard from './DashboardCard';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PersonIcon from '@mui/icons-material/Person';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import LaunchIcon from '@mui/icons-material/Launch';
import axios from 'axios';
import config from '../../common/config';
import { useAuth } from '../../hooks/AuthContext';

const Section = styled.section`
	padding: 2rem 1rem;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const Grid = styled.div`
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 1.2rem;
	width: 100%;

	@media (max-width: 1200px) {
		grid-template-columns: repeat(3, 1fr);
	}

	@media (max-width: 900px) {
		grid-template-columns: repeat(2, 1fr);
	}

	@media (max-width: 600px) {
		grid-template-columns: 1fr;
	}
`;

const initialCardData = [
	// {
	// 	title: 'DWCT Coin',
	// 	value: '0 DWCT',
	// 	footer: 'DWCT',
	// 	icon: MonetizationOnIcon,
	// },
	{
		title: 'My Donation',
		value: '$0',
		footer: 'Amount',
		icon: MonetizationOnIcon,
	},
	// {
	// 	title: 'Boosting Entry 2.0',
	// 	value: 'Click Here',
	// 	icon: LaunchIcon,
	// 	link: '/dashboard/boosting-income',
	// },
	{
		title: 'Welfare Fund',
		value: '0',
		icon: MonetizationOnIcon,
		link: '/dashboard/welfare-fund',
	},
	// {
	// 	title: 'Global Welfare Fund',
	// 	value: '$0',
	// 	icon: MonetizationOnIcon,
	// 	footer: 'Amount',
	// },
	{
		title: 'Total Bonus',
		value: '$0',
		icon: MonetizationOnIcon,
		footer: 'Amount',
	},
	// {
	// 	title: 'Booster Bonus',
	// 	value: '$0 [0/0]',
	// 	icon: MonetizationOnIcon,
	// 	footer: 'Amount',
	// },
	{ title: 'Referral Bonus', value: '$0', icon: LaunchIcon, footer: 'Amount' },
	{
		title: 'Royalty Bonus',
		value: '$0',
		icon: MonetizationOnIcon,
		footer: 'Amount',
	},
	{ title: 'Level Bonus', value: '$0', icon: LaunchIcon, footer: 'Amount' },
	{
		title: 'Reward Bonus',
		value: '$0',
		icon: LaunchIcon,
		footer: 'Amount',
		link: '/dashboard/reward',
	},
	{ title: 'Auto Pool Bonus', value: '0', icon: LaunchIcon, footer: 'Amount' },
	{
		title: 'My Referrals',
		value: '0 Members',
		footer: 'Members',
		icon: LaunchIcon,
	},
	{
		title: 'Total Community',
		value: '0 Members',
		footer: 'Members',
		icon: LaunchIcon,
	},
	{
		title: 'Stronger Zone',
		value: '$0',
		icon: MonetizationOnIcon,
		footer: 'Business',
	},
	{
		title: 'Weaker Zone',
		value: '$0',
		icon: MonetizationOnIcon,
		footer: 'Business',
	},
	{
		title: 'Total Donation',
		value: '$0',
		icon: MonetizationOnIcon,
		footer: 'Business',
	},
	{
		title: 'New Signup',
		value: 'Click Here',
		icon: TouchAppIcon,
		link: '/signup',
	},
	{ title: 'Referral Link', value: 'Copy', icon: FileCopyIcon },
	{
		title: 'Stronger Trade Zone Business',
		value: '$0',
		icon: MonetizationOnIcon,
		footer: 'Business',
	},
	{
		title: 'Weaker Trade Zone Business',
		value: '$0',
		icon: MonetizationOnIcon,
		footer: 'Business',
	},
	{
		title: 'Reward',
		value: 'Director Club',
		icon: MonetizationOnIcon,
		link: 'dashboard/reward',
	},
];

const Dashboard = () => {
	const [cardData, setCardData] = useState(initialCardData);
	const server_url = `${config.server_url}/dashboard`;
	const { profile } = useAuth();
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${server_url}`, {
					params: { userId: profile?.Id },
					headers: {
						'Content-Type': 'application/json',
					},
					withCredentials: true,
				});
				const data = response.data.data;
				const transformedData = transformDataToCardFormat(data);
				setCardData(transformedData);
			} catch (error) {
				console.error('Error fetching dashboard data:', error);
			}
		};

		fetchData();
	}, [server_url, profile?.userId]);

	const transformDataToCardFormat = (data) => {
		return [
			// {
			// 	title: 'DWCT Coin',
			// 	value: `${data.CoinCount || 0} DWCT`,
			// 	footer: 'DWCT',
			// 	icon: MonetizationOnIcon,
			// },
			{
				title: 'My Donation',
				value: `$${data.SocialFundAmount || 0}`,
				footer: 'Amount',
				icon: MonetizationOnIcon,
			},
			// {
			// 	title: 'Boosting Entry 2.0',
			// 	value: 'Click Here',
			// 	icon: LaunchIcon,
			// 	link: '/dashboard/boosting-income',
			// },
			{
				title: 'Welfare Fund',
				value: `$${data.WelfareWalletBalance || 0}`,
				icon: MonetizationOnIcon,
				link: '/dashboard/welfare-fund',
			},
			// {
			// 	title: 'Global Welfare Fund',
			// 	value: `$${data.SocialFund || 0}`,
			// 	icon: MonetizationOnIcon,
			// 	footer: 'Amount',
			// },
			{
				title: 'Total Bonus',
				value: `$${data.TotalEarned || 0}`,
				icon: MonetizationOnIcon,
				footer: 'Amount',
			},
			// {
			// 	title: 'Booster Bonus',
			// 	value: `$${data.BoostingGlobalPoolLevelIncomeDetails || '0 [0/0]'}`,
			// 	icon: MonetizationOnIcon,
			// 	footer: 'Amount',
			// },
			{
				title: 'Referral Bonus',
				value: `$${data.SponsorIncome || 0}`,
				icon: LaunchIcon,
				footer: 'Amount',
			},
			{
				title: 'Royalty Bonus',
				value: `$${data.Performance || 0}`,
				icon: MonetizationOnIcon,
				footer: 'Amount',
			},
			{
				title: 'Level Bonus',
				value: `$${data.LevelIncome || 0}`,
				icon: LaunchIcon,
				footer: 'Amount',
			},
			{
				title: 'Reward Bonus',
				value: `$${data.RewardIncome || 0}`,
				icon: LaunchIcon,
				footer: 'Amount',
				link: '/dashboard/reward',
			},
			{
				title: 'Auto Pool Bonus',
				value: `$${data.PoolIncome || 0}`,
				icon: LaunchIcon,
				footer: 'Amount',
			},
			{
				title: 'My Referrals',
				value: `${data.SponsorCount || 0} Members`,
				footer: 'Members',
				icon: LaunchIcon,
			},
			{
				title: 'Total Community',
				value: `${data.MyTeamCount || 0} Members`,
				footer: 'Members',
				icon: LaunchIcon,
			},
			{
				title: 'Stronger Zone',
				value: `$${data.StrongerZoneBusiness || 0} `,
				icon: MonetizationOnIcon,
				footer: 'Business',
			},
			{
				title: 'Weaker Zone',
				value: `$${data.WeakerZoneBusiness || 0} `,
				icon: MonetizationOnIcon,
				footer: 'Business',
			},
			{
				title: 'Total Donation',
				value: data.TotalZoneBusines || '$0',
				icon: MonetizationOnIcon,
				footer: 'Business',
			},
			{
				title: 'New Signup',
				value: 'Click Here',
				icon: TouchAppIcon,
				link: '/signup',
			},
			{
				title: 'Referral Link',
				value: 'Copy',
				icon: FileCopyIcon,
				isCopyLink: true,
			},
			// {
			// 	title: 'Stronger Trade Zone Business',
			// 	value: data.StrongerTradeBusiness || '$0',
			// 	icon: MonetizationOnIcon,
			// 	footer: 'Business',
			// },
			// {
			// 	title: 'Weaker Trade Zone Business',
			// 	value: data.WekaerTradeBusiness || '$0',
			// 	icon: MonetizationOnIcon,
			// 	footer: 'Business',
			// },
			// {
			// 	title: 'Reward',
			// 	value: data.NextRank || 'Director Club',
			// 	icon: MonetizationOnIcon,
			// 	link: 'dashboard/reward',
			// },
		];
	};

	return (
		<Section>
			<Grid>
				{cardData.map((card, index) => (
					<DashboardCard
						key={index}
						title={card.title}
						value={card.value}
						icon={card.icon}
						footer={card.footer}
						link={card.link}
						isCopyLink={card?.isCopyLink}
					/>
				))}
			</Grid>
		</Section>
	);
};

export default Dashboard;
