import React from 'react';
import { Box, Grid, TextField, MenuItem, Button, Typography } from '@mui/material';
import { styled } from '@mui/system';

const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: '#294e46',
  color: '#fff',
  borderRadius: theme.shape.borderRadius,
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1),
  },
}));

const ReTopupBox = styled(Box)(({ theme }) => ({
  border: '1px solid #fff',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  color: '#fff',
}));

const HistoryBox = styled(Box)(({ theme }) => ({
  border: '1px solid #fff',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  color: '#fff',
}));

const ReTopupID = () => {
  return (
    <Container>
      <Typography variant="h6" gutterBottom>ReTopUp ID</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <ReTopupBox>
            <Typography variant="h6" gutterBottom>ReTop Up Id</Typography>
            <Typography variant="h3" gutterBottom>$69.71</Typography>
            <TextField
              select
              label="Wallet Type"
              fullWidth
              variant="outlined"
              margin="normal"
              SelectProps={{
                native: true,
              }}
              InputLabelProps={{
                style: { color: '#fff' },
              }}
              InputProps={{
                style: { color: '#fff' },
              }}
            >
              <option value="">My Wallet</option>
              {/* Add other wallet options here */}
            </TextField>
            <TextField
              label="Re-Topup Amount"
              fullWidth
              variant="outlined"
              margin="normal"
              InputLabelProps={{
                style: { color: '#fff' },
              }}
              InputProps={{
                style: { color: '#fff' },
              }}
            />
            <Button variant="contained" color="primary" fullWidth>RETOPUP NOW</Button>
          </ReTopupBox>
        </Grid>
        <Grid item xs={12} md={6}>
          <HistoryBox>
            <Typography variant="h6" gutterBottom>Your Re-Topup History</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="From Date"
                  type="date"
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                    style: { color: '#fff' },
                  }}
                  InputProps={{
                    style: { color: '#fff' },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="To Date"
                  type="date"
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                    style: { color: '#fff' },
                  }}
                  InputProps={{
                    style: { color: '#fff' },
                  }}
                />
              </Grid>
            </Grid>
            <Button variant="contained" color="primary" fullWidth>Search</Button>
            <Typography variant="body1" style={{ marginTop: '16px', color: 'yellow' }}>
              No record found!! Try to change filters
            </Typography>
          </HistoryBox>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ReTopupID;
