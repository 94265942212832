import React from 'react';
import './index.css';
import {
	BrowserRouter as Router,
	Route,
	Routes,
	useLocation,
} from 'react-router-dom';
import GlobalStyle from './styles/GlobalStyles';
import Header from './components/Header';
import Home from './pages/Home';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import Footer from './components/Footer';
import ForgotPassword from './components/ForgotPassword';
import DashboardLayout from './pages/DashboardLayout';
import PrivateRoute from './components/PrivateRouter';
import { AuthProvider } from './hooks/AuthContext';
const AppContent = () => {
	const location = useLocation();
	const hideHeaderAndFooter = location.pathname === '/';
	console.log(process.env.REACT_APP_BACKEND_URL);

	return (
		<>
			{hideHeaderAndFooter && <Header />}
			<Routes>
				<Route path='/' element={<Home />} />
				<Route path='/login' element={<Login />} />
				<Route path='/signup' element={<SignUp />} />
				<Route path='/forgot-password' element={<ForgotPassword />} />
				{/* <Route path='/dashboard/*' element={<PrivateRoute element={DashboardLayout} />} /> */}
				<Route path='/dashboard/*' element={<DashboardLayout />} />
			</Routes>
			{hideHeaderAndFooter && <Footer />}
		</>
	);
};

function App() {
	return (
		<Router>
			<AuthProvider>
				<GlobalStyle />
				<AppContent />
			</AuthProvider>
		</Router>
	);
}

export default App;
