import React, { useState } from 'react';
import styled from 'styled-components';

const HeaderWrapper = styled.header`
	position: sticky;
	top: 0;
	background-color: #fff;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	z-index: 1000;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1rem 2rem;

	@media (max-width: 768px) {
		padding: 1rem;
	}
`;

const Logo = styled.div`
	font-size: 1.5rem;
	font-weight: bold;
`;

const Nav = styled.nav`
	display: flex;
	gap: 1rem;

	@media (max-width: 768px) {
		display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
		flex-direction: column;
		gap: 0.5rem;
		position: absolute;
		top: 100%;
		left: 0;
		width: 100%;
		background-color: #fff;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
		padding: 1rem;
	}
`;

const NavLink = styled.a`
	text-decoration: none;
	color: #000;
	font-size: 1rem;
	cursor: pointer;

	&:hover {
		color: #007bff;
	}

	@media (max-width: 768px) {
		font-size: 1.25rem;
	}
`;

const BurgerMenuButton = styled.button`
	background: none;
	border: none;
	cursor: pointer;
	display: none;

	@media (max-width: 768px) {
		display: block;
	}
`;

const Header = () => {
	const [isOpen, setIsOpen] = useState(false);

	const toggleMenu = () => {
		setIsOpen(!isOpen);
	};

	const handleScroll = (id) => {
		const element = document.getElementById(id);
		if (element) {
			element.scrollIntoView({ behavior: 'smooth' });
			setIsOpen(false);
		}
	};

	return (
		<HeaderWrapper>
			<Logo>The Digital World Community</Logo>
			<>
				<Nav isOpen={isOpen}>
					<NavLink onClick={() => handleScroll('home')}>HOME</NavLink>
					<NavLink onClick={() => handleScroll('activities')}>
						ACTIVITIES
					</NavLink>
					<NavLink onClick={() => handleScroll('about-us')}>ABOUT US</NavLink>
					<NavLink onClick={() => handleScroll('management')}>
						MANAGEMENT
					</NavLink>
					<NavLink onClick={() => handleScroll('dwc-technology')}>
						DWC TECHNOLOGY
					</NavLink>
					<NavLink onClick={() => handleScroll('contact')}>CONTACT</NavLink>
				</Nav>
				<BurgerMenuButton onClick={toggleMenu}>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='24'
						height='24'
						viewBox='0 0 24 24'>
						<path d='M0 0h24v24H0z' fill='none' />
						<path d='M3 18h18v-2H3v2zM3 13h18v-2H3v2zM3 6v2h18V6H3z' />
					</svg>
				</BurgerMenuButton>
			</>
		</HeaderWrapper>
	);
};

export default Header;
