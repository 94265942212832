import { styled } from '@mui/system';
import { Typography } from '@mui/material';
import { Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

export const CommonHeader = styled(Typography)(({ theme }) => ({
	backgroundColor: '#234a3d',
	padding: '16px',
	fontWeight: '500',
	borderRadius: '8px',
	color: '#fff',
	textAlign: 'left',
}));

export const GradientButton = ({
	onClick,
	text,
	width = '150px',
	Icon = <SearchIcon style={{ fontWeight: '600', fontSize: '20px' }} />,
}) => {
	return (
		<Button
			variant='contained'
			onClick={onClick}
			startIcon={Icon}
			sx={{
				background: 'linear-gradient(to right, #134E5E, #71B280)',
				color: '#fff',
				height: '56px',
				width: { width },
				fontSize: '16px',
				fontWeight: '500',
				'&:hover': {
					background: 'linear-gradient(to right, #71B280, #134E5E)',
				},
			}}>
			{text}
		</Button>
	);
};
