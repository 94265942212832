import React from 'react';
import styled from 'styled-components';

const Card = styled.div`
	display: flex;
	align-items: center;
	background: #fff;
	border: 1px solid #ddd;
	border-radius: 8px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
	margin: 1rem auto;
	padding: 1rem;
	box-sizing: border-box;
	width: 60%;

	@media (max-width: 767px) {
		width: 90%; /* Adjust width for mobile */
		flex-direction: column;
		align-items: flex-start;
	}
`;

const CardContent = styled.div`
	flex: 1;
	padding-right: 1rem;

	@media (max-width: 767px) {
		padding-right: 0; /* Remove padding for mobile */
		margin-bottom: 1rem; /* Add margin between image and text for mobile */
		text-align: left;
	}
`;

const CardTitle = styled.h3`
	font-size: 1.25rem;
	color: #333;
	margin-bottom: 0.5rem;
`;

const CardSubtitle = styled.h4`
	font-size: 1rem;
	color: #666;
	margin-bottom: 0.75rem;
`;

const CardText = styled.p`
	font-size: 0.875rem;
	color: #555;
`;

const CardImage = styled.img`
	width: 150px;
	height: 150px;
	border-radius: 8px;
	object-fit: cover;

	@media (max-width: 767px) {
		width: 100%; /* Adjust image width for mobile */
		height: auto; /* Auto height for mobile */
		margin-bottom: 1rem; /* Add margin below image for mobile */
	}
`;

const ActivityCard = ({ title, subtitle, text, imageUrl }) => {
	return (
		<Card>
			<CardContent>
				<CardTitle>{title}</CardTitle>
				<CardSubtitle>{subtitle}</CardSubtitle>
				<CardText>{text}</CardText>
			</CardContent>
			<CardImage src={imageUrl} alt={title} />
		</Card>
	);
};

export default ActivityCard;
