import React from 'react';
import { Container, Box } from '@mui/material';
import styled from 'styled-components';
import Sidebar from '../components/dashboard/Sidebar';
import Header from '../components/dashboard/Header';
import { Routes, Route } from 'react-router-dom';
import Dashboard from '../components/dashboard/Dashboard';
import MyProfile from '../components/dashboard/MyProfile';
import KYC from '../components/dashboard/KYC';
import WelcomeLetter from '../components/dashboard/WelcomeLetter';
import AccountSettings from '../components/dashboard/AccountSettings';
import PremiereSupply from '../components/dashboard/PremiereSupply';
import DWCTLedger from '../components/dashboard/DWCTLedger';
import Swap from '../components/dashboard/Swap';
import Transfer from '../components/dashboard/Transfer';
import Support from '../components/dashboard/Support';
import CoreTeam from '../components/dashboard/CoreTeam';
import Activate from '../components/dashboard/Activate';
import ActivateHistory from '../components/dashboard/ActivateHistory';
import TradingNow from '../components/dashboard/TradingNow';
import TradingDashboard from '../components/dashboard/TradingDashboard';
import TradingDividendReport from '../components/dashboard/TradingDividendReport';
import TradingTeamReport from '../components/dashboard/TradingTeamReport';
import RetopupID from '../components/dashboard/RetopupID';
import RetopupIDHistory from '../components/dashboard/RetopupIDHistory';
import Withdraw from '../components/dashboard/Withdraw';
import WithdrawHistory from '../components/dashboard/WithdrawHistory';
import DepositUSDT from '../components/dashboard/DepositUSDT';
import DepositHistory from '../components/dashboard/DepositHistory';
import TransferToWallet from '../components/dashboard/TransferToWallet';
import TransferHistory from '../components/dashboard/TransferHistory';
import PlayColorGame from '../components/dashboard/PlayColorGame';
import PlayNumberGame from '../components/dashboard/PlayNumberGame';
import ReferralBonus from '../components/dashboard/ReferralBonus';
import WelfareFund from '../components/dashboard/WelfareFund';
import LevelSubscriptionEarning from '../components/dashboard/LevelSubscriptionEarning';
import TeamRevenue from '../components/dashboard/TeamRevenue';
import BoostingIncome from '../components/dashboard/BoostingIncome';
import RoyaltyBonus from '../components/dashboard/RoyaltyBonus';
import PoolStatus from '../components/dashboard/PoolStatus';
import Reward from '../components/dashboard/Reward';
import MemberTotalReport from '../components/dashboard/MemberTotalReport';
import TradingClubBonus from '../components/dashboard/TradingClubBonus';
import TeamDownline from '../components/dashboard/TeamDownline';
import MyDirect from '../components/dashboard/MyDirect';
import LevelWiseReport from '../components/dashboard/LevelWiseReport';
import RankAchiever from '../components/dashboard/RankAchiever';
import About from '../components/dashboard/About';
import BusinessPlan from '../components/dashboard/BusinessPlan';
import NewsEvents from '../components/dashboard/NewsEvents';

const Layout = styled(Box)`
	display: flex;
	height: 100vh;
	width: 100%;
	background-color: red;
`;

const MainContent = styled(Box)`
	flex: 1;
	display: flex;
	flex-direction: column;
	height: 100vh;
	overflow: auto;
	background-color: #f9f9f9;
`;

const ContentArea = styled(Box)`
	flex: 1;
	overflow-y: auto;
`;

const DashboardLayout = () => {
	return (
		<Layout>
			<Sidebar />
			<MainContent>
				<Header />
				<ContentArea>
					<Container
						sx={{ paddingBottom: '4rem', paddingTop: '2rem' }}
						maxWidth='100%'>
						<Routes>
							<Route path='/' element={<Dashboard />} />
							<Route path='my-profile' element={<MyProfile />} />
							<Route path='kyc' element={<KYC />} />
							<Route path='welcome-letter' element={<WelcomeLetter />} />
							<Route path='account-settings' element={<AccountSettings />} />
							<Route path='premiere-supply' element={<PremiereSupply />} />
							<Route path='dwct-ledger' element={<DWCTLedger />} />
							<Route path='swap' element={<Swap />} />
							<Route path='transfer' element={<Transfer />} />
							<Route path='core-team' element={<CoreTeam />} />
							<Route path='activate' element={<Activate />} />
							<Route path='activate-history' element={<ActivateHistory />} />
							<Route path='trading-now' element={<TradingNow />} />
							<Route path='trading-dashboard' element={<TradingDashboard />} />
							<Route
								path='trading-dividend-report'
								element={<TradingDividendReport />}
							/>
							<Route
								path='trading-team-report'
								element={<TradingTeamReport />}
							/>
							<Route path='retopup-id' element={<RetopupID />} />
							<Route path='retopup-id-history' element={<RetopupIDHistory />} />
							<Route path='withdraw' element={<Withdraw />} />
							<Route path='withdraw-history' element={<WithdrawHistory />} />
							<Route path='deposit-usdt' element={<DepositUSDT />} />
							<Route path='deposit-history' element={<DepositHistory />} />
							<Route path='transfer-to-wallet' element={<TransferToWallet />} />
							<Route path='transfer-history' element={<TransferHistory />} />
							<Route path='play-color-game' element={<PlayColorGame />} />
							<Route path='play-number-game' element={<PlayNumberGame />} />
							<Route path='referral-bonus' element={<ReferralBonus />} />
							<Route path='welfare-fund' element={<WelfareFund />} />
							<Route
								path='level-subscription-earning'
								element={<LevelSubscriptionEarning />}
							/>
							<Route path='team-revenue' element={<TeamRevenue />} />
							<Route path='boosting-income' element={<BoostingIncome />} />
							<Route path='royalty-bonus' element={<RoyaltyBonus />} />
							<Route path='pool-status' element={<PoolStatus />} />
							<Route path='reward' element={<Reward />} />
							<Route
								path='member-total-report'
								element={<MemberTotalReport />}
							/>
							<Route path='trading-club-bonus' element={<TradingClubBonus />} />
							<Route path='team-downline' element={<TeamDownline />} />
							<Route path='my-direct' element={<MyDirect />} />
							<Route path='level-wise-report' element={<LevelWiseReport />} />
							<Route path='rank-achiever' element={<RankAchiever />} />
							<Route path='support' element={<Support />} />
							<Route path='about' element={<About />} />
							<Route path='business-plan' element={<BusinessPlan />} />
							<Route path='news-events' element={<NewsEvents />} />
						</Routes>
					</Container>
				</ContentArea>
			</MainContent>
		</Layout>
	);
};

export default DashboardLayout;
