exports.countries = [
	{ id: 1, name: 'Afghanistan', code: 'AFG' },
	{ id: 2, name: 'Albania', code: 'ALB' },
	{ id: 3, name: 'Algeria', code: 'DZA' },
	{ id: 4, name: 'Andorra', code: 'AND' },
	{ id: 5, name: 'Angola', code: 'AGO' },
	{ id: 6, name: 'Argentina', code: 'ARG' },
	{ id: 7, name: 'Armenia', code: 'ARM' },
	{ id: 8, name: 'Australia', code: 'AUS' },
	{ id: 9, name: 'Austria', code: 'AUT' },
	{ id: 10, name: 'Azerbaijan', code: 'AZE' },
	{ id: 11, name: 'Bahamas', code: 'BHS' },
	{ id: 12, name: 'Bahrain', code: 'BHR' },
	{ id: 13, name: 'Bangladesh', code: 'BGD' },
	{ id: 14, name: 'Barbados', code: 'BRB' },
	{ id: 15, name: 'Belarus', code: 'BLR' },
	{ id: 16, name: 'Belgium', code: 'BEL' },
	{ id: 17, name: 'Belize', code: 'BLZ' },
	{ id: 18, name: 'Benin', code: 'BEN' },
	{ id: 19, name: 'Bhutan', code: 'BTN' },
	{ id: 20, name: 'Bolivia', code: 'BOL' },
	{ id: 21, name: 'Bosnia and Herzegovina', code: 'BIH' },
	{ id: 22, name: 'Botswana', code: 'BWA' },
	{ id: 23, name: 'Brazil', code: 'BRA' },
	{ id: 24, name: 'Brunei Darussalam', code: 'BRN' },
	{ id: 25, name: 'Bulgaria', code: 'BGR' },
	{ id: 26, name: 'Burkina Faso', code: 'BFA' },
	{ id: 27, name: 'Burundi', code: 'BDI' },
	{ id: 28, name: 'Cabo Verde', code: 'CPV' },
	{ id: 29, name: 'Cambodia', code: 'KHM' },
	{ id: 30, name: 'Cameroon', code: 'CMR' },
	{ id: 31, name: 'Canada', code: 'CAN' },
	{ id: 32, name: 'Central African Republic', code: 'CAF' },
	{ id: 33, name: 'Chad', code: 'TCD' },
	{ id: 34, name: 'Chile', code: 'CHL' },
	{ id: 35, name: 'China', code: 'CHN' },
	{ id: 36, name: 'Colombia', code: 'COL' },
	{ id: 37, name: 'Comoros', code: 'COM' },
	{ id: 38, name: 'Congo', code: 'COG' },
	{ id: 39, name: 'Costa Rica', code: 'CRI' },
	{ id: 40, name: 'Croatia', code: 'HRV' },
	{ id: 41, name: 'Cuba', code: 'CUB' },
	{ id: 42, name: 'Cyprus', code: 'CYP' },
	{ id: 43, name: 'Czech Republic', code: 'CZE' },
	{ id: 44, name: 'Denmark', code: 'DNK' },
	{ id: 45, name: 'Djibouti', code: 'DJI' },
	{ id: 46, name: 'Dominica', code: 'DMA' },
	{ id: 47, name: 'Dominican Republic', code: 'DOM' },
	{ id: 48, name: 'Ecuador', code: 'ECU' },
	{ id: 49, name: 'Egypt', code: 'EGY' },
	{ id: 50, name: 'El Salvador', code: 'SLV' },
	{ id: 51, name: 'Equatorial Guinea', code: 'GNQ' },
	{ id: 52, name: 'Eritrea', code: 'ERI' },
	{ id: 53, name: 'Estonia', code: 'EST' },
	{ id: 54, name: 'Eswatini', code: 'SWZ' },
	{ id: 55, name: 'Ethiopia', code: 'ETH' },
	{ id: 56, name: 'Fiji', code: 'FJI' },
	{ id: 57, name: 'Finland', code: 'FIN' },
	{ id: 58, name: 'France', code: 'FRA' },
	{ id: 59, name: 'Gabon', code: 'GAB' },
	{ id: 60, name: 'Gambia', code: 'GMB' },
	{ id: 61, name: 'Georgia', code: 'GEO' },
	{ id: 62, name: 'Germany', code: 'DEU' },
	{ id: 63, name: 'Ghana', code: 'GHA' },
	{ id: 64, name: 'Greece', code: 'GRC' },
	{ id: 65, name: 'Grenada', code: 'GRD' },
	{ id: 66, name: 'Guatemala', code: 'GTM' },
	{ id: 67, name: 'Guinea', code: 'GIN' },
	{ id: 68, name: 'Guinea-Bissau', code: 'GNB' },
	{ id: 69, name: 'Guyana', code: 'GUY' },
	{ id: 70, name: 'Haiti', code: 'HTI' },
	{ id: 71, name: 'Honduras', code: 'HND' },
	{ id: 72, name: 'Hungary', code: 'HUN' },
	{ id: 73, name: 'Iceland', code: 'ISL' },
	{ id: 74, name: 'India', code: 'IND' },
	{ id: 75, name: 'Indonesia', code: 'IDN' },
	{ id: 76, name: 'Iran', code: 'IRN' },
	{ id: 77, name: 'Iraq', code: 'IRQ' },
	{ id: 78, name: 'Ireland', code: 'IRL' },
	{ id: 79, name: 'Israel', code: 'ISR' },
	{ id: 80, name: 'Italy', code: 'ITA' },
	{ id: 81, name: 'Jamaica', code: 'JAM' },
	{ id: 82, name: 'Japan', code: 'JPN' },
	{ id: 83, name: 'Jordan', code: 'JOR' },
	{ id: 84, name: 'Kazakhstan', code: 'KAZ' },
	{ id: 85, name: 'Kenya', code: 'KEN' },
	{ id: 86, name: 'Kiribati', code: 'KIR' },
	{ id: 87, name: 'Kuwait', code: 'KWT' },
	{ id: 88, name: 'Kyrgyzstan', code: 'KGZ' },
	{ id: 89, name: 'Laos', code: 'LAO' },
	{ id: 90, name: 'Latvia', code: 'LVA' },
	{ id: 91, name: 'Lebanon', code: 'LBN' },
	{ id: 92, name: 'Lesotho', code: 'LSO' },
	{ id: 93, name: 'Liberia', code: 'LBR' },
	{ id: 94, name: 'Libya', code: 'LBY' },
	{ id: 95, name: 'Liechtenstein', code: 'LIE' },
	{ id: 96, name: 'Lithuania', code: 'LTU' },
	{ id: 97, name: 'Luxembourg', code: 'LUX' },
	{ id: 98, name: 'Madagascar', code: 'MDG' },
	{ id: 99, name: 'Malawi', code: 'MWI' },
	{ id: 100, name: 'Malaysia', code: 'MYS' },
	{ id: 101, name: 'Maldives', code: 'MDV' },
	{ id: 102, name: 'Mali', code: 'MLI' },
	{ id: 103, name: 'Malta', code: 'MLT' },
	{ id: 104, name: 'Marshall Islands', code: 'MHL' },
	{ id: 105, name: 'Mauritania', code: 'MRT' },
	{ id: 106, name: 'Mauritius', code: 'MUS' },
	{ id: 107, name: 'Mexico', code: 'MEX' },
	{ id: 108, name: 'Micronesia', code: 'FSM' },
	{ id: 109, name: 'Moldova', code: 'MDA' },
	{ id: 110, name: 'Monaco', code: 'MCO' },
	{ id: 111, name: 'Mongolia', code: 'MNG' },
	{ id: 112, name: 'Montenegro', code: 'MNE' },
	{ id: 113, name: 'Morocco', code: 'MAR' },
	{ id: 114, name: 'Mozambique', code: 'MOZ' },
	{ id: 115, name: 'Myanmar', code: 'MMR' },
	{ id: 116, name: 'Namibia', code: 'NAM' },
	{ id: 117, name: 'Nauru', code: 'NRU' },
	{ id: 118, name: 'Nepal', code: 'NPL' },
	{ id: 119, name: 'Netherlands', code: 'NLD' },
	{ id: 120, name: 'New Zealand', code: 'NZL' },
	{ id: 121, name: 'Nicaragua', code: 'NIC' },
	{ id: 122, name: 'Niger', code: 'NER' },
	{ id: 123, name: 'Nigeria', code: 'NGA' },
	{ id: 124, name: 'North Korea', code: 'PRK' },
	{ id: 125, name: 'North Macedonia', code: 'MKD' },
	{ id: 126, name: 'Norway', code: 'NOR' },
	{ id: 127, name: 'Oman', code: 'OMN' },
	{ id: 128, name: 'Pakistan', code: 'PAK' },
	{ id: 129, name: 'Palau', code: 'PLW' },
	{ id: 130, name: 'Palestine', code: 'PSE' },
	{ id: 131, name: 'Panama', code: 'PAN' },
	{ id: 132, name: 'Papua New Guinea', code: 'PNG' },
	{ id: 133, name: 'Paraguay', code: 'PRY' },
	{ id: 134, name: 'Peru', code: 'PER' },
	{ id: 135, name: 'Philippines', code: 'PHL' },
	{ id: 136, name: 'Poland', code: 'POL' },
	{ id: 137, name: 'Portugal', code: 'PRT' },
	{ id: 138, name: 'Qatar', code: 'QAT' },
	{ id: 139, name: 'Romania', code: 'ROU' },
	{ id: 140, name: 'Russia', code: 'RUS' },
	{ id: 141, name: 'Rwanda', code: 'RWA' },
	{ id: 142, name: 'Saint Kitts and Nevis', code: 'KNA' },
	{ id: 143, name: 'Saint Lucia', code: 'LCA' },
	{ id: 144, name: 'Saint Vincent and the Grenadines', code: 'VCT' },
	{ id: 145, name: 'Samoa', code: 'WSM' },
	{ id: 146, name: 'San Marino', code: 'SMR' },
	{ id: 147, name: 'Sao Tome and Principe', code: 'STP' },
	{ id: 148, name: 'Saudi Arabia', code: 'SAU' },
	{ id: 149, name: 'Senegal', code: 'SEN' },
	{ id: 150, name: 'Serbia', code: 'SRB' },
	{ id: 151, name: 'Seychelles', code: 'SYC' },
	{ id: 152, name: 'Sierra Leone', code: 'SLE' },
	{ id: 153, name: 'Singapore', code: 'SGP' },
	{ id: 154, name: 'Slovakia', code: 'SVK' },
	{ id: 155, name: 'Slovenia', code: 'SVN' },
	{ id: 156, name: 'Solomon Islands', code: 'SLB' },
	{ id: 157, name: 'Somalia', code: 'SOM' },
	{ id: 158, name: 'South Africa', code: 'ZAF' },
	{ id: 159, name: 'South Korea', code: 'KOR' },
	{ id: 160, name: 'South Sudan', code: 'SSD' },
	{ id: 161, name: 'Spain', code: 'ESP' },
	{ id: 162, name: 'Sri Lanka', code: 'LKA' },
	{ id: 163, name: 'Sudan', code: 'SDN' },
	{ id: 164, name: 'Suriname', code: 'SUR' },
	{ id: 165, name: 'Sweden', code: 'SWE' },
	{ id: 166, name: 'Switzerland', code: 'CHE' },
	{ id: 167, name: 'Syria', code: 'SYR' },
	{ id: 168, name: 'Taiwan', code: 'TWN' },
	{ id: 169, name: 'Tajikistan', code: 'TJK' },
	{ id: 170, name: 'Tanzania', code: 'TZA' },
	{ id: 171, name: 'Thailand', code: 'THA' },
	{ id: 172, name: 'Timor-Leste', code: 'TLS' },
	{ id: 173, name: 'Togo', code: 'TGO' },
	{ id: 174, name: 'Tonga', code: 'TON' },
	{ id: 175, name: 'Trinidad and Tobago', code: 'TTO' },
	{ id: 176, name: 'Tunisia', code: 'TUN' },
	{ id: 177, name: 'Turkey', code: 'TUR' },
	{ id: 178, name: 'Turkmenistan', code: 'TKM' },
	{ id: 179, name: 'Tuvalu', code: 'TUV' },
	{ id: 180, name: 'Uganda', code: 'UGA' },
	{ id: 181, name: 'Ukraine', code: 'UKR' },
	{ id: 182, name: 'United Arab Emirates', code: 'ARE' },
	{ id: 183, name: 'United Kingdom', code: 'GBR' },
	{ id: 184, name: 'United States', code: 'USA' },
	{ id: 185, name: 'Uruguay', code: 'URY' },
	{ id: 186, name: 'Uzbekistan', code: 'UZB' },
	{ id: 187, name: 'Vanuatu', code: 'VUT' },
	{ id: 188, name: 'Venezuela', code: 'VEN' },
	{ id: 189, name: 'Vietnam', code: 'VNM' },
	{ id: 190, name: 'Yemen', code: 'YEM' },
	{ id: 191, name: 'Zambia', code: 'ZMB' },
	{ id: 192, name: 'Zimbabwe', code: 'ZWE' },
];
