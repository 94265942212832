import React from 'react';
import styled from 'styled-components';
import welfareData from './welfareData';

const Section = styled.section`
	padding: 2rem 1rem;
	background-color: #ffffff;
	text-align: center;

	@media (min-width: 768px) {
		padding: 3rem 2rem;
	}
`;

const Title = styled.h2`
	font-size: 2rem;
	color: #333;
	margin-bottom: 1rem;

	@media (min-width: 768px) {
		font-size: 2.5rem;
	}
`;

const GridContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
`;

const Grid = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	gap: 1rem;
	width: 100%;
	max-width: 60%;

	@media (min-width: 768px) {
		grid-template-columns: 1fr 1fr;
		gap: 2rem;
	}

	@media (max-width: 768px) {
		max-width: 90%;
	}
`;

const Card = styled.div`
	background-color: #f5f5f5;
	padding: 1rem;
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	text-align: left;

	img {
		width: 50px;
		border-radius: 8px;
		margin-bottom: 1rem;
	}

	h3 {
		font-size: 1.25rem;
		color: #333;
		margin-bottom: 0.5rem;
	}

	p {
		font-size: 1rem;
		color: #555;
		line-height: 1.5;
	}
`;

const WelfareSection = () => {
	return (
		<Section>
			<Title>WELFARE</Title>
			<GridContainer>
				<Grid>
					{welfareData.map((item, index) => (
						<Card key={index}>
							<img src={item.imageUrl} alt={item.title} />
							<h3>{item.title}</h3>
							<p>{item.text}</p>
						</Card>
					))}
				</Grid>
			</GridContainer>
		</Section>
	);
};

export default WelfareSection;
