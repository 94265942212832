import styled from 'styled-components';

const Button = styled.button`
	background: ${(props) => (props.primary ? '#e74c3c' : 'transparent')};
	color: ${(props) => (props.primary ? 'white' : '#e74c3c')};
	border: 2px solid #e74c3c;
	padding: 0.5rem 1rem;
	font-size: 1rem;
	border-radius: 5px;
	transition: background 0.3s, color 0.3s;

	&:hover {
		background: ${(props) => (props.primary ? '#c0392b' : '#e74c3c')};
		color: white;
	}
`;

export default Button;
