import React, { useState } from 'react';

function DWCTLedger() {


	const [fromDate, setFromDate] = useState('');
	const [toDate, setToDate] = useState('');

	const handleFromDateChange = (event) => {
		setFromDate(event.target.value);
	};

	const handleToDateChange = (event) => {
		setToDate(event.target.value);
	};

	const handleSubmit = () => {
		// Handle form submission
		console.log('Form submitted:', { fromDate, toDate });
	};

	return (
		<div className="supply-container">
			<div className="supply-header">
				<h2 className="supply-title">Premiere Supply Report</h2>
			</div>
			<div className="supply-form">
				<div className="supply-form-row">
					<label htmlFor="fromDate" className="supply-form-label">
						From Date
					</label>
					<input
						type="date"
						id="fromDate"
						value={fromDate}
						onChange={handleFromDateChange}
						className="supply-form-input"
					/>
				</div>
				<div className="supply-form-row">
					<label htmlFor="toDate" className="supply-form-label">
						To Date
					</label>
					<input
						type="date"
						id="toDate"
						value={toDate}
						onChange={handleToDateChange}
						className="supply-form-input"
					/>
				</div>
				<button type="button" onClick={handleSubmit} className="supply-form-button">
					<span className="supply-search-icon">Q</span> Search
				</button>
			</div>
			<div className="supply-message">
				<span className="supply-message-icon">!</span> No Record Found!!
			</div>
		</div>
	);
};


export default DWCTLedger;
