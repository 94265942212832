// src/hooks/useGridData.js
import { useEffect, useState } from 'react';
import { loadList } from '../utils/api';
import { columnsConfig } from '../common/ColumnsConfig';
const useGridData = (pageName, filters) => {
	const [columns, setColumns] = useState([]);
	const [rows, setRows] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const loadGridData = async () => {
			if (pageName && columnsConfig[pageName]) {
				setColumns(columnsConfig[pageName]);
				try {
					const data = await loadList(pageName, filters);
					console.log('data', data);
					setRows(data);
				} catch (error) {
					console.error('Failed to load data:', error);
				} finally {
					setLoading(false);
				}
			}
		};

		setLoading(true);
		loadGridData();
	}, [pageName, filters]);

	return { columns, rows, loading };
};

export default useGridData;
