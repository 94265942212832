import React, { useState } from 'react';
import {
	Container,
	Box,
	TextField,
	Button,
	Checkbox,
	FormControlLabel,
	Typography,
	Alert,
	InputAdornment,
	IconButton,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { Lock, Person, Visibility, VisibilityOff } from '@mui/icons-material';
import styled from 'styled-components';
import { useAuth } from '../hooks/AuthContext';
import config from '../common/config';
import { setTokenInCookies } from '../utils/common';

const FormWrapper = styled(Box)`
	background: #fff;
	padding: 2rem;
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	max-width: 500px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	background: linear-gradient(180deg, #e0e0e0, #f0f0f0);
`;

const ImageWrapper = styled(Box)`
	width: 100%;
	display: flex;
	justify-content: center;
	margin-bottom: 1rem;
`;

const FormTitle = styled(Typography)`
	color: #2c3e50;
	margin-bottom: 1rem;
`;

const FormSubTitle = styled(Typography)`
	color: #16a085;
	margin-bottom: 2rem;
`;

const Login = () => {
	const { login, loadUserInfo } = useAuth();
	const [formData, setFormData] = useState({
		username: '',
		password: '',
		rememberMe: false,
	});
	const [alert, setAlert] = useState(null);
	const [showPassword, setShowPassword] = useState(false);

	const handleChange = (e) => {
		const { name, value, type, checked } = e.target;
		setFormData((prevData) => ({
			...prevData,
			[name]: type === 'checkbox' ? checked : value,
		}));
	};

	const togglePasswordVisibility = () => {
		setShowPassword((prevShowPassword) => !prevShowPassword);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const { username, password } = formData;

		if (!username || !password) {
			setAlert('User Name and Password are required');
			return;
		}

		try {
			const response = await fetch(`${config.server_url}/login`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(formData),
			});

			const data = await response.json();
			if (data.success) {
				await loadUserInfo(data?.user?.id, data.token);
				login(data.token);
				setTokenInCookies(data.token);
			} else {
				setAlert('Invalid username or password');
			}
		} catch (error) {
			setAlert('An error occurred. Please try again later.');
		}
	};

	return (
		<Container
			sx={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				height: '100vh',
				backgroundColor: '#f9f9f9',
			}}>
			<FormWrapper>
				<ImageWrapper>
					{/* <img
						src='/assets/images/login_image.png'
						alt='Login'
						style={{ width: '50%', borderRadius: '8px' }}
					/> */}
					<img
						src='logo.png'
						alt='Logo'
						style={{ width: '30%', borderRadius: '8px' }}
					/>
				</ImageWrapper>
				<FormTitle variant='h4'>Sign In</FormTitle>
				<FormSubTitle variant='subtitle1'>
					Build Your Brand's Voice
				</FormSubTitle>
				<form onSubmit={handleSubmit}>
					<TextField
						fullWidth
						variant='outlined'
						margin='normal'
						placeholder='Enter Username'
						label='User Name'
						name='username'
						value={formData.username}
						onChange={handleChange}
						InputProps={{
							startAdornment: (
								<Person sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
							),
						}}
					/>
					<TextField
						fullWidth
						variant='outlined'
						margin='normal'
						placeholder='Enter Password'
						label='Password'
						type={showPassword ? 'text' : 'password'}
						name='password'
						value={formData.password}
						onChange={handleChange}
						InputProps={{
							startAdornment: (
								<Lock sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
							),
							endAdornment: (
								<InputAdornment position='end'>
									<IconButton onClick={togglePasswordVisibility} edge='end'>
										{showPassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
					<FormControlLabel
						control={
							<Checkbox
								name='rememberMe'
								checked={formData.rememberMe}
								onChange={handleChange}
							/>
						}
						label='Remember Me'
						sx={{ alignSelf: 'flex-start' }}
					/>
					<Button
						type='submit'
						variant='contained'
						color='primary'
						fullWidth
						sx={{
							padding: '0.75rem',
							marginTop: '1rem',
							background: 'linear-gradient(to right, #16a085, #1abc9c)',
						}}>
						Sign In
					</Button>
					<Box
						mt={2}
						display='flex'
						justifyContent='space-between'
						width='100%'>
						<Typography variant='body2'>
							<a
								href='/forgot-password'
								style={{ color: '#16a085', textDecoration: 'none' }}>
								Forgot Password?
							</a>
						</Typography>
						<Typography variant='body2'>
							Don't have an account?{' '}
							<a
								href='/signup'
								style={{ color: '#16a085', textDecoration: 'none' }}>
								Sign up
							</a>
						</Typography>
					</Box>
				</form>
				{alert && (
					<Alert sx={{ marginTop: '20px' }} severity='error'>
						{alert}
					</Alert>
				)}
			</FormWrapper>
		</Container>
	);
};

export default Login;
