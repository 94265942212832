import React from 'react';
import { Box, TextField, Button, Typography, Tabs, Tab } from '@mui/material';
import { styled } from '@mui/system';

const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  background:'linear-gradient(81deg, #0f243e 0%, #4fb1e0 100%)',
  color: '#fff',
  borderRadius: theme.shape.borderRadius,
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1),
  },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
  minWidth: 0,
  fontWeight: theme.typography?.fontWeightRegular || 400,
  marginRight: theme.spacing(1),
  color: '#fff',
  '&.Mui-selected': {
    color: '#fff',
    fontWeight: theme.typography?.fontWeightMedium || 500,
  },
}));

const WalletBox = styled(Box)(({ theme }) => ({
  border: '1px solid #fff',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  marginTop: theme.spacing(2),
  color: '#fff',
}));

const AccountSettings = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container>
      <Typography variant="h6" gutterBottom>Account Setting</Typography>
      <Tabs value={value} onChange={handleChange} aria-label="account settings tabs">
        <StyledTab label="Wallet" />
      </Tabs>
      <WalletBox>
        <Typography variant="h6" gutterBottom>USDT Wallet Address</Typography>
        <TextField
          label="USDT Wallet Address"
          fullWidth
          variant="outlined"
          margin="normal"
          InputLabelProps={{
            style: { color: '#fff' },
          }}
          InputProps={{
            style: { color: '#fff' },
          }}
        />
        <Button variant="contained" color="primary">Update</Button>
      </WalletBox>
    </Container>
  );
};

export default AccountSettings;
