import {
	combineReducers,
	applyMiddleware,
	legacy_createStore as createStore,
} from 'redux';
import { thunk } from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { authReducers, userReducer } from './reducers/authReducers';

const reducer = combineReducers({
	auth: authReducers,
	user: userReducer,
});

let initialState = {};

const middleware = [thunk];

const store = createStore(
	reducer,
	initialState,
	// composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
