import React from 'react';
import { Box, Grid, TextField, MenuItem, Button, Typography } from '@mui/material';
import { styled } from '@mui/system';

const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: '#294e46',
  color: '#fff',
  borderRadius: theme.shape.borderRadius,
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1),
  },
}));

const WithdrawBox = styled(Box)(({ theme }) => ({
  border: '1px solid #fff',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  color: '#fff',
}));

const HistoryBox = styled(Box)(({ theme }) => ({
  border: '1px solid #fff',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  color: '#fff',
}));

const WithdrawFund = () => {
  return (
    <Container>
      <Typography variant="h6" gutterBottom>Withdraw Fund</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <WithdrawBox>
            <Typography variant="h6" gutterBottom>Wallet Balance</Typography>
            <Typography variant="h3" gutterBottom>$0</Typography>
            <Typography variant="body2" gutterBottom>Last updated:</Typography>
            <TextField
              select
              label="Wallet Type"
              fullWidth
              variant="outlined"
              margin="normal"
              SelectProps={{
                native: true,
              }}
              InputLabelProps={{
                style: { color: '#fff' },
              }}
              InputProps={{
                style: { color: '#fff' },
              }}
            >
              <option value="">Select Wallet</option>
              {/* Add wallet options here */}
            </TextField>
            <TextField
              select
              label="Withdraw Mode"
              fullWidth
              variant="outlined"
              margin="normal"
              SelectProps={{
                native: true,
              }}
              InputLabelProps={{
                style: { color: '#fff' },
              }}
              InputProps={{
                style: { color: '#fff' },
              }}
            >
              <option value="USDT Address">USDT Address</option>
              {/* Add other modes here */}
            </TextField>
            <TextField
              label="Withdraw Amount"
              fullWidth
              variant="outlined"
              margin="normal"
              InputLabelProps={{
                style: { color: '#fff' },
              }}
              InputProps={{
                style: { color: '#fff' },
              }}
            />
            <Grid container spacing={2}>
              <Grid item xs={9}>
                <TextField
                  label="OTP"
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  InputLabelProps={{
                    style: { color: '#fff' },
                  }}
                  InputProps={{
                    style: { color: '#fff' },
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <Button variant="contained" color="primary" fullWidth style={{ marginTop: 16 }}>Send OTP</Button>
              </Grid>
            </Grid>
            <Button variant="contained" color="primary" fullWidth style={{ marginTop: 16 }}>Submit</Button>
          </WithdrawBox>
        </Grid>
        <Grid item xs={12} md={6}>
          <HistoryBox>
            <Typography variant="h6" gutterBottom>Your Withdrawal History</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="From Date"
                  type="date"
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                    style: { color: '#fff' },
                  }}
                  InputProps={{
                    style: { color: '#fff' },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="To Date"
                  type="date"
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                    style: { color: '#fff' },
                  }}
                  InputProps={{
                    style: { color: '#fff' },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  select
                  label="Select Status"
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  SelectProps={{
                    native: true,
                  }}
                  InputLabelProps={{
                    style: { color: '#fff' },
                  }}
                  InputProps={{
                    style: { color: '#fff' },
                  }}
                >
                  <option value="Pending">Pending</option>
                  <option value="Completed">Completed</option>
                  {/* Add other statuses here */}
                </TextField>
              </Grid>
            </Grid>
            <Button variant="contained" color="primary" fullWidth>Search</Button>
            <Typography variant="body1" style={{ marginTop: '16px', color: 'yellow' }}>
              No record found!! Try to change filters
            </Typography>
          </HistoryBox>
        </Grid>
      </Grid>
    </Container>
  );
};

export default WithdrawFund;
