import React from 'react';
import styled from 'styled-components';
import ActivityCard from './ActivityCard';
import activities from './activitiesData';

const Section = styled.section`
	padding: 2rem 1rem;
	background-color: #f5f5f5;
	text-align: center;

	@media (min-width: 768px) {
		padding: 3rem 2rem;
	}
`;

const Title = styled.h2`
	font-size: 2rem;
	color: #333;
	margin-bottom: 1rem;

	@media (min-width: 768px) {
		font-size: 2.5rem;
	}
`;

const Description = styled.p`
	font-size: 1rem;
	color: #555;
	width: 80%;
	margin: 0 auto 2rem auto;
	line-height: 1.5;

	@media (min-width: 768px) {
		width: 60%;
	}
`;

const ActivitiesSection = () => {
	return (
		<>
			<Section id="activities">
				<Title>OUR ACTIVITIES</Title>
				<Description>
					Helped all the people, being influenced by it, further helped the
					person who helped, in this way a chain went ahead and people were very
					impressed by it, now it works as a crowdfund P to P support system,
					where a person helps another person.
				</Description>
				<div className="activities-grid">
					{activities.map((activity, index) => (
						<ActivityCard
							key={index}
							title={activity.title}
							subtitle={activity.subtitle}
							text={activity.text}
							imageUrl={activity.imageUrl}
						/>
					))}
				</div>
			</Section>
		</>
	);
};

export default ActivitiesSection;
