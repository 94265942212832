import React from 'react';
import { Box, Typography, Button, useMediaQuery } from '@mui/material';
import styled from 'styled-components';
import { useAuth } from '../../hooks/AuthContext';

const HeaderContainer = styled(Box)`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 0rem;

	@media (max-width: 600px) {
		flex-direction: column;
		align-items: center;
		text-align: center;
		padding: 0.5rem;
	}
`;

const WalletSummary = styled(Box)`
	display: flex;
	gap: 1rem;

	@media (max-width: 600px) {
		flex-direction: column;
		align-items: center;
		width: 100%;
	}
`;

const HeaderLeft = styled(Box)`
	display: flex;
	gap: 1rem;
	align-items: center;

	@media (max-width: 600px) {
		flex-direction: column;
		align-items: center;
		gap: 0.5rem;
	}
`;

const TopHeaderContainer = styled(Box)`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	background-color: #fff;
	padding: 0.5rem 1rem;

	@media (max-width: 600px) {
		flex-direction: column;
		align-items: flex-end;
		text-align: center;
		padding: 0.5rem;
	}
`;

const StyledButton = styled(Button)`
	flex-grow: 1;
	min-width: 120px;
	width: ${(props) => (props.isMobile ? '70%' : 'auto')};
`;

const Header = () => {
	const walletLabels = [
		{ label: 'My Wallet', key: 'myWallet' },
		{ label: 'Fund Wallet', key: 'fundWallet' },
		{ label: 'Auto Pool Wallet', key: 'autoPoolWallet' },
	];
	const isMobile = useMediaQuery('(max-width:600px)');
	const { profile, logout } = useAuth();

	return (
		<>
			<TopHeaderContainer>
				<HeaderLeft>
					<span
						style={{ color: 'black', fontSize: isMobile ? '12px' : '14px' }}>
						Last Login: {profile?.lastLogin}
					</span>
					<span
						style={{ color: 'black', fontSize: isMobile ? '12px' : '14px' }}>
						{profile?.language}
					</span>
				</HeaderLeft>
				<div
					className='header-right'
					style={{ marginLeft: isMobile ? 0 : '1rem', cursor: 'pointer' }}
					onClick={logout}>
					<img
						src='/images/profile.png'
						alt='Profile'
						style={{ height: '30px', width: '30px', borderRadius: '50%' }}
					/>
				</div>
			</TopHeaderContainer>
			<HeaderContainer className='dashboard-header-container'>
				<Box
					style={{
						flexBasis: isMobile ? '100%' : 'auto',
						marginBottom: isMobile ? '1rem' : '0',
					}}>
					<Typography
						style={{
							fontSize: isMobile ? '1.5rem' : '2rem',
							fontFamily: 'sans-serif',
							fontWeight: 750,
							color: '#000',
						}}
						variant='h4'>
						Welcome, {profile?.name}
					</Typography>
					<div
						style={
							isMobile
								? {
										display: 'flex',
										gap: '12px',
										justifyContent: 'center',
										alignItems: 'center',
								  }
								: { display: 'block' }
						}>
						<p
							className='activationDate'
							style={{ margin: '0.5rem 0', marginLeft: '2rem' }}>
							MY RANK <span className='exText'>NOW</span>
						</p>
						<span
							className='valCls'
							style={{
								backgroundColor: '#f12711',
								padding: '5px 15px',
								borderRadius: '26px',
								color: 'white',
								marginLeft: '2rem',
							}}
							id='RankId'>
							--
						</span>
					</div>
				</Box>
				<WalletSummary>
					{walletLabels.map(({ label, key }, index) => (
						<StyledButton
							className='btn-linear-green'
							variant='contained'
							isMobile={isMobile}
							key={index}>
							{label} | ${profile ? profile[key] || 0.0 : 0.0}
						</StyledButton>
					))}
				</WalletSummary>
			</HeaderContainer>
		</>
	);
};

export default Header;
