import React from 'react';
import { Box, Typography, Link, IconButton, Grid } from '@mui/material';
import { styled } from '@mui/system';
import { Twitter, Telegram, YouTube } from '@mui/icons-material';

const FooterBackground = styled(Box)(({ theme }) => ({
	backgroundColor: '#333',
	color: '#fff',
	padding: theme.spacing(4, 2),
	textAlign: 'center',
}));

const FooterContainer = styled(Box)(({ theme }) => ({
	width: '60%',
	margin: '0 auto', // Center the footer content
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
	fontSize: '1.25rem',
	fontWeight: 'bold',
	marginBottom: theme.spacing(2),
}));

const FooterLink = styled(Link)(({ theme }) => ({
	color: '#d4a217',
	textDecoration: 'none',
	display: 'block',
	marginBottom: theme.spacing(1),
}));

const IconContainer = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	marginTop: theme.spacing(2),
}));

const ImageContainer = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	flexWrap: 'wrap',
	'& img': {
		width: '45%',
		height: 'auto',
		borderRadius: '4px',
		margin: theme.spacing(0.5),
	},
}));

const Footer = () => {
	return (
		<FooterBackground>
			<FooterContainer>
				<Grid container spacing={2}>
					<Grid item xs={12} md={4}>
						<SectionTitle>ABOUT US</SectionTitle>
						<Typography variant='body2' paragraph>
							The Digital world started from a small town in the Netherlands,
							where it was created as a support system to help the local people.
						</Typography>
						<Typography variant='body2' paragraph>
							Contact Us
						</Typography>
						<FooterLink href='mailto:info@digitalworldss.com'>
							info@thedigitalworlds.com
						</FooterLink>
						<FooterLink href='http://www.digitalworldss.com' target='_blank'>
							www.thedigitalworlds.com
						</FooterLink>
						<IconContainer>
							<IconButton
								color='inherit'
								href='https://twitter.com'
								target='_blank'>
								<Twitter />
							</IconButton>
							<IconButton
								color='inherit'
								href='https://telegram.org'
								target='_blank'>
								<Telegram />
							</IconButton>
							<IconButton
								color='inherit'
								href='https://youtube.com'
								target='_blank'>
								<YouTube />
							</IconButton>
						</IconContainer>
					</Grid>
					<Grid item xs={12} md={4}>
						<SectionTitle>NEWS POST</SectionTitle>
						<Typography variant='body2'>
							<strong>Collaboration</strong>
							<br />
							The Crowd World Community emphasizes working together and
							sharing
						</Typography>
						<Typography variant='body2' mt={2}>
							<strong>Innovation</strong>
							<br />
							The Crowd World Community values creativity and forward-thinking
						</Typography>
						<Typography variant='body2' mt={2}>
							<strong>Inclusivity</strong>
							<br />
							The Crowd World Community is open to people from all backgrounds
						</Typography>
					</Grid>
					<Grid item xs={12} md={4}>
						<SectionTitle>OUR ACTIVITY</SectionTitle>
						<ImageContainer>
							<img src='/images/crypto_coin.jpg' alt='Activity 1' />
							<img src='/images/gaming_esports.jpg' alt='Activity 2' />
							<img src='/images/E-commerce.jpg' alt='Activity 3' />
							<img src='/images/utility.jpg' alt='Activity 4' />
						</ImageContainer>
					</Grid>
				</Grid>
				<Typography variant='body2' mt={4}>
					© Copyright All rights reserved. The Crowd World Community 2024-2050
					World Wide
				</Typography>
			</FooterContainer>
		</FooterBackground>
	);
};

export default Footer;
