import React from 'react';

function WelcomeLetter() {
	return (
		<div className="welcome-letter-container">
			<div className="welcome-letter-header">
				<div className="welcome-letter-title">Welcome Letter</div>
				<div className="welcome-letter-subtitle">Welcome to Digital world</div>
			</div>
			<div className="welcome-letter-content">
				<div className="welcome-letter-left">
					<div className="welcome-letter-logo">
						<img src="https://www.digitalworld.in/wp-content/uploads/2023/02/Digital-World-Logo.png" alt="Digital World" />
					</div>
					<div className="welcome-letter-chairman-message">
						<div className="welcome-letter-chairman-message-quote">
							<span className="welcome-letter-chairman-message-quote-left">&#8220;</span>
							<span className="welcome-letter-chairman-message-quote-right">&#8221;</span>
						</div>
						<div className="welcome-letter-chairman-message-title">CHAIRMAN'S MESSAGE</div>
					</div>
				</div>
				<div className="welcome-letter-right">
					<div className="welcome-letter-right-title">Digital World</div>
					<div className="welcome-letter-right-subtitle">Chairman</div>
					<div className="welcome-letter-right-text">
						Dear Digital World,
						<br />
						Digital world is a self-financing mutual aid foundation. This is a non-profit organization, which works for social Welfare fund assistance at the international
						level. Digital world p2p support system is a community welfare crowd funding program for social improve responsibility Every Members.
						<br />
						<br />
						Welfare Fund Support We help our members according to their needs- Medical Emergency, Family survival, Education Support, Marriage Fund, Natural
						Disaster, Equity Based startup, Happy home gurukul startup
						<br />
						<br />
						How it's Work Digital world uses P2P, Donation & Reward Base cloud fund platform to work for various social purposes.
						<br />
						<br />
						Management Digital world is an Automation Community Organization, All of whom are promoted on the basis of good deeds. No one is in the position of
						owner in the digital world.
						<br />
						<br />
						Wishing you a rewarding and more meaningful association with Digital World.
						<br />
						<br />
						In your success,
						<br />
						Digital World
						<br />
						Founder & C.E.O.
					</div>
				</div>
			</div>
		</div>
	);
}

export default WelcomeLetter;
