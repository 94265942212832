import React from 'react';
import { Grid, TextField, MenuItem } from '@mui/material';
import { GradientButton } from './commonStyle';

const levels = [
	{ value: 'all', label: '--ALL--' },
	{ value: '1', label: 'Level 1' },
	{ value: '2', label: 'Level 2' },
	{ value: '3', label: 'Level 3' },
];

export const LevelFilter = ({ filters, handleFilterChange, handleSearch }) => {
	const inputCustomStyle = {
		background:'linear-gradient(81deg, #0f243e 0%, #4fb1e0 100%)',
		borderRadius: 1,
		'& .MuiInputBase-root': {
			color: '#fff',
		},
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: '#fff',
		},
		'& .MuiInputLabel-root': {
			color: '#fff',
		},
	};
	return (
		<Grid
			container
			spacing={2}
			alignItems='center'
			sx={{ mb: 2, p: 2, borderRadius: 1 }}>
			<Grid item xs={12} sm={4}>
				<TextField
					select
					label='Choose Level No'
					value={filters.status}
					onChange={(event) => handleFilterChange('Level', event.target.value)}
					variant='outlined'
					fullWidth
					sx={inputCustomStyle}>
					{levels.map((option) => (
						<MenuItem key={option.value} value={option.value}>
							{option.label}
						</MenuItem>
					))}
				</TextField>
			</Grid>
			<Grid item xs={12} sm={3}>
				<GradientButton onClick={handleSearch} text='Search' />
			</Grid>
		</Grid>
	);
};
