import React, { useState } from 'react';
import GridPage from '../GridPage';

const LevelSubscriptionEarning = () => {
	return (
		<>
			<GridPage
				pageName='LevelIncomeList'
				title='Community Income'
				requiredSection={{
					globalFilter: true,
					levelFilter: false,
					status: false,
				}}
			/>
		</>
	);
};

export default LevelSubscriptionEarning;
