// src/utils/api.js
import axios from 'axios';
import config from '../common/config';

/**
 *
 * @param {string} actionName - The action to perform (e.g., "list").
 * @param {Object} para - The parameters for the action, including UserId, FromDate, and ToDate.
 * @returns {Promise<Object>} - The response data from the API.
 *
 * Loading data for selected grid
 */
export const loadList = async (actionName, para) => {
	const url = `${config.server_url}/load`;
	try {
		const response = await axios.post(
			url,
			{
				action: 'list',
				actionName,
				para: JSON.stringify(para),
			},
			{
				headers: {
					'Content-Type': 'application/json',
				},
				withCredentials: true,
			}
		);
		const { data } = response?.data;
		const { header, rows } = data;
		let result = {};
		if (header) {
			result = rows.map((row, index) => ({
				...row,
				id: row.SNo || index,
			}));
		} else {
			result = data.map((row, index) => ({
				...row,
				id: row.SNo || index,
			}));
		}

		return result;
	} catch (error) {
		console.error('Error fetching data:', error);
		throw error;
	}
};

export const loadLookup = async (action, lookupTypeId) => {
	const url = `${config.server_url}/lookup?action=${action}&lookupTypeId=${lookupTypeId}`;
	try {
		const response = await axios.get(url, {
			headers: {
				'Content-Type': 'application/json',
			},
			withCredentials: true,
		});

		return response.data;
	} catch (error) {
		console.error('Error fetching lookup data:', error);
		throw error;
	}
};
