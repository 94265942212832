import React, { useState } from 'react';

function Transfer() {

  const [dwctTokenBalance, setDwctTokenBalance] = useState(30);
  const [dwctReceivingAddress, setDwctReceivingAddress] = useState('');
  const [tokenAmount, setTokenAmount] = useState(0);
  const [otp, setOtp] = useState('');

  const handleDwctReceivingAddressChange = (e) => {
    setDwctReceivingAddress(e.target.value);
  };

  const handleTokenAmountChange = (e) => {
    setTokenAmount(e.target.value);
  };

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  const handleSubmit = () => {
    // Handle form submission logic here
    console.log('Form submitted!');
  };

  return (
    <div className="transfer-container">
      <div className="transfer-token">
        <div className="transfer-token-header">
          <h2 className="transfer-token-title">Transfer Token</h2>
        </div>
        <div className="transfer-token-content">
          <div className="transfer-token-balance">
            <h3 className="transfer-token-balance-title">DWCT Token Balance</h3>
            <div className="transfer-token-balance-value">{dwctTokenBalance} DWCT</div>
            <p className="transfer-token-balance-text">You can swap only 5% every month</p>
          </div>
          <div className="transfer-token-address">
            <label className="transfer-token-address-label" htmlFor="dwct-receiving-address">
              DWCT Receiving Address(BEP20 Chain)
            </label>
            <input
              className="transfer-token-address-input"
              type="text"
              id="dwct-receiving-address"
              placeholder="Enter DWCT Token Address"
              value={dwctReceivingAddress}
              onChange={handleDwctReceivingAddressChange}
            />
          </div>
          <div className="transfer-token-amount">
            <label className="transfer-token-amount-label" htmlFor="token-amount">
              Token Amount
            </label>
            <input
              className="transfer-token-amount-input"
              type="number"
              id="token-amount"
              placeholder="0"
              value={tokenAmount}
              onChange={handleTokenAmountChange}
            />
          </div>
          <div className="transfer-token-otp">
            <label className="transfer-token-otp-label" htmlFor="otp">
              OTP
            </label>
            <input
              className="transfer-token-otp-input"
              type="text"
              id="otp"
              placeholder="Enter OTP"
              value={otp}
              onChange={handleOtpChange}
            />
            <button className="transfer-token-otp-button" onClick={() => alert('OTP sent!')}>
              Send OTP
            </button>
          </div>
          <button className="transfer-token-submit-button" onClick={handleSubmit}>
            <i className="transfer-token-submit-icon fas fa-arrow-right"></i>
            Submit
          </button>
        </div>
      </div>
      <div className="transfer-history">
        <div className="transfer-history-header">
          <h3 className="transfer-history-title">Your Swapping History</h3>
        </div>
        <div className="transfer-history-content">
          <div className="transfer-history-filters">
            <div className="transfer-history-filter">
              <label className="transfer-history-filter-label" htmlFor="from-date">
                From Date
              </label>
              <input
                className="transfer-history-filter-input"
                type="date"
                id="from-date"
                placeholder="01-May-2024"
              />
            </div>
            <div className="transfer-history-filter">
              <label className="transfer-history-filter-label" htmlFor="to-date">
                To Date
              </label>
              <input
                className="transfer-history-filter-input"
                type="date"
                id="to-date"
                placeholder="01-June-2024"
              />
            </div>
            <div className="transfer-history-filter">
              <label className="transfer-history-filter-label" htmlFor="select-status">
                Select Status
              </label>
              <select className="transfer-history-filter-select" id="select-status">
                <option value="pending">Pending</option>
                <option value="completed">Completed</option>
                <option value="failed">Failed</option>
              </select>
              <button className="transfer-history-filter-search-button">
                <i className="transfer-history-filter-search-icon fas fa-search"></i>
                Search
              </button>
            </div>
          </div>
          <div className="transfer-history-message">
            <i className="transfer-history-message-icon fas fa-exclamation-triangle"></i>
            No record found!! Try to change filters
          </div>
        </div>
      </div>
    </div>
  );
};

export default Transfer;
