import React, { useState, useEffect } from 'react';
import { Box, Grid, TextField, MenuItem, Typography } from '@mui/material';
import { styled } from '@mui/system';
import GridPage from '../GridPage';
import { loadLookup } from '../../utils/api';
import { GradientButton } from '../commonStyle';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useAuth } from '../../hooks/AuthContext';
import config from '../../common/config';
import axios from 'axios';
import Swal from 'sweetalert2';

const Container = styled(Box)(({ theme }) => ({
	padding: theme.spacing(2),
	backgroundColor: '#3A584D',
	color: '#fff',
	borderRadius: theme.shape.borderRadius,
	[theme.breakpoints.down('sm')]: {
		padding: theme.spacing(1),
	},
}));

const DonationBox = styled(Box)(({ theme }) => ({
	border: '1px solid #fff',
	borderRadius: theme.shape.borderRadius,
	padding: theme.spacing(2),
	marginBottom: theme.spacing(2),
	color: '#fff',
}));

const HistoryBox = styled(Box)(({ theme }) => ({
	border: '1px solid #fff',
	borderRadius: theme.shape.borderRadius,
	padding: theme.spacing(2),
	marginLeft: theme.spacing(2),
	[theme.breakpoints.down('sm')]: {
		marginLeft: 0,
		marginTop: theme.spacing(2),
	},
	color: '#fff',
}));

const inputCustomStyle = {
	backgroundColor: '#294e46',
	borderRadius: 1,
	'& .MuiInputBase-root': {
		color: '#fff',
	},
	'& .MuiOutlinedInput-notchedOutline': {
		borderColor: '#fff',
	},
	'& .MuiInputLabel-root': {
		color: '#fff',
	},
	marginTop: '30px',
};

const DonationForm = () => {
	const [packages, setPackages] = useState([]);
	const [selectedPackage, setSelectedPackage] = useState('');
	const [donationAmount, setDonationAmount] = useState('');
	const { profile } = useAuth();
	const [error, setError] = useState('');

	useEffect(() => {
		const fetchPackages = async () => {
			try {
				const data = await loadLookup('package', 1);
				setPackages(data.data);
			} catch (error) {
				console.error('Error fetching packages:', error);
				Swal.fire('Error', 'Error fetching packages', 'error');
			}
		};

		fetchPackages();
	}, []);

	const handleDonation = async () => {
		if (!selectedPackage || !donationAmount) {
			Swal.fire(
				'Error',
				'Please select a package and enter the donation amount',
				'error'
			);
			return;
		}

		try {
			const response = await axios.post(
				`${config.server_url}/purchaseToken`,
				{
					userId: profile.Id,
					packageId: selectedPackage,
					donationAmount: parseFloat(donationAmount),
				},
				{
					headers: {
						'Content-Type': 'application/json',
					},
					withCredentials: true,
				}
			);

			if (response.data.success) {
				Swal.fire('Success', 'Donation made successfully', 'success');
				setSelectedPackage('');
				setDonationAmount('');
			} else {
				Swal.fire('Error', 'Donation failed', 'error');
			}
		} catch (error) {
			Swal.fire('Error', 'Error making donation', 'error');
			console.error('Error making donation:', error);
		}
	};

	return (
		<Container>
			<Grid container spacing={2}>
				<Grid item xs={12} md={4}>
					<DonationBox>
						<Typography variant='h6' gutterBottom>
							Donation
						</Typography>
						<Typography variant='h3' gutterBottom>
							${profile?.fundWallet || 0}
						</Typography>

						<TextField
							select
							label='Select Package'
							value={selectedPackage}
							onChange={(e) => setSelectedPackage(e.target.value)}
							variant='outlined'
							fullWidth
							sx={inputCustomStyle}>
							<option value=''>Select Package</option>
							{packages.map((pkg) => (
								<MenuItem key={pkg.LookupId} value={pkg.LookupId}>
									{pkg.DisplayValue} [{pkg.Amount}]
								</MenuItem>
							))}
						</TextField>

						<TextField
							label='Donation Amount'
							variant='outlined'
							fullWidth
							margin='normal'
							value={donationAmount}
							onChange={(e) => setDonationAmount(e.target.value)}
							sx={inputCustomStyle}
						/>
						{error && (
							<Typography variant='body2' color='error' gutterBottom>
								{error}
							</Typography>
						)}
						<GradientButton
							onClick={handleDonation}
							text='DONATE NOW'
							width='200px'
							Icon={
								<ExitToAppIcon
									style={{ fontWeight: '600', fontSize: '20px' }}
								/>
							}
						/>
					</DonationBox>
				</Grid>
				<Grid item xs={12} md={8}>
					<HistoryBox>
						<GridPage
							pageName='TokenPurchaseHistory'
							title='Your Donation History'
							requiredSection={{
								globalFilter: true,
								levelFilter: false,
								status: true,
							}}
						/>
					</HistoryBox>
				</Grid>
			</Grid>
		</Container>
	);
};

export default DonationForm;
