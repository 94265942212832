import React, { useState } from 'react';
import GridPage from '../GridPage';

const WithdrawHistory = () => {
	return (
		<>
			<GridPage
				pageName='WithdrawFundHistory'
				title='Your Withdraw  History'
				requiredSection={{
					globalFilter: true,
					levelFilter: false,
					status: true,
				}}
			/>
		</>
	);
};

export default WithdrawHistory;
