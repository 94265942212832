import React, { useState } from "react";

function Swap() {

	const [tokenAmount, setTokenAmount] = useState("");
	const [dwctAddress, setDwctAddress] = useState("");
	const [otp, setOtp] = useState("");
	const [fromDate, setFromDate] = useState("");
	const [toDate, setToDate] = useState("");

	const handleTokenAmountChange = (event) => {
		setTokenAmount(event.target.value);
	};

	const handleDwctAddressChange = (event) => {
		setDwctAddress(event.target.value);
	};

	const handleOtpChange = (event) => {
		setOtp(event.target.value);
	};

	const handleFromDateChange = (event) => {
		setFromDate(event.target.value);
	};

	const handleToDateChange = (event) => {
		setToDate(event.target.value);
	};

	const handleSubmit = () => {
		// Handle form submission logic here
	};

	return (
		<div className="container-swap">
			<div className="header">
				<div className="title">Swap Token</div>
				<div className="title">Swap DWCT Token</div>
			</div>
			<div className="content">
				<div className="left-side">
					<div className="section">
						<div className="section-title">Fund Wallet Balance</div>
						<div className="section-content">
							<div className="field">
								<label htmlFor="usdt">0 USDT</label>
								<p>1 DWCT=$0.1144</p>
							</div>
							<div className="field">
								<label htmlFor="dwct-address">
									DWCT Receiving Address(BEP20 Chain)
								</label>
								<input
									type="text"
									id="dwct-address"
									placeholder="Enter DWCT Token Address"
									value={dwctAddress}
									onChange={handleDwctAddressChange}
								/>
							</div>
							<div className="field">
								<label htmlFor="token-amount">Token Amount</label>
								<input
									type="text"
									id="token-amount"
									placeholder="0"
									value={tokenAmount}
									onChange={handleTokenAmountChange}
								/>
							</div>
							<div className="field">
								<label htmlFor="otp">OTP</label>
								<input
									type="text"
									id="otp"
									placeholder="Enter OTP"
									value={otp}
									onChange={handleOtpChange}
								/>
							</div>
							<button className="send-otp-button">Send OTP</button>
							<button className="submit-button" onClick={handleSubmit}>
								Submit
							</button>
						</div>
					</div>
				</div>
				<div className="right-side">
					<div className="section">
						<div className="section-title">Your Purchase History</div>
						<div className="section-content">
							<div className="field">
								<label htmlFor="from-date">From Date</label>
								<input
									type="date"
									id="from-date"
									value={fromDate}
									onChange={handleFromDateChange}
								/>
							</div>
							<div className="field">
								<label htmlFor="to-date">To Date</label>
								<input
									type="date"
									id="to-date"
									value={toDate}
									onChange={handleToDateChange}
								/>
							</div>
							<div className="field">
								<label htmlFor="status">Select Status</label>
								<select id="status">
									<option value="pending">Pending</option>
								</select>
							</div>
							<button className="search-button">Search</button>
							<div className="error-message">
								No record found!! Try to change filters
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};


export default Swap;
