import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../hooks/AuthContext';

const PrivateRoute = ({ element: Component, ...rest }) => {
	const { token } = useAuth();

	return token ? <Component {...rest} /> : <Navigate to='/login' />;
};

export default PrivateRoute;
