import React from 'react';
import styled from 'styled-components';
import ManagementImage from '../assets/images/management.jpg';

const AdditionalSection = styled.section`
	display: flex;
	justify-content: flex-start;
	padding: 2rem 1rem;
	background-color: #fff;

	@media (min-width: 768px) {
		padding: 3rem 2rem;
	}
	@media (max-width: 768px) {
		display: flex;
		flex-direction: column;
		padding: 3rem 2rem;
	}
`;

const SectionContainer = styled.div`
	width: 32%;
	margin-left: 18%;
	background-color: #fff;
	padding: 1rem;

	@media (min-width: 768px) {
		padding: 2rem;
	}

	/* Mobile Styles */
	@media (max-width: 767px) {
		width: 100%;
		margin: 0 auto;
		text-align: center;
	}
`;

const SectionTitle = styled.h3`
	font-size: 1.8rem;
	color: #333;
	margin-bottom: 1rem;
`;

const SectionHighlight = styled.div`
	width: 50px;
	height: 5px;
	background-color: #f39c12;
	margin: 0.5rem 0 1rem 0;
`;

const Text = styled.p`
	font-size: 1rem;
	color: #555;
	line-height: 1.6;
	margin-bottom: 1rem;
`;

const ManagementImg = styled.img`
	width: 30%;
	height: 30%;
	border-radius: 8px;
	object-fit: cover;

	/* Mobile Styles */
	@media (max-width: 767px) {
		width: 100%; /* Adjust image width for mobile */
		height: auto;
		margin: 1rem auto; /* Center image on mobile */
	}
`;

const AdditionalInfoSection = () => {
	return (
		<AdditionalSection id='management'>
			<SectionContainer>
				<SectionTitle>Management</SectionTitle>
				<SectionHighlight />
				<Text>
					The Crowd World Community is an automation community system, of
					which active members are promoted based on their good deeds.
				</Text>
				<Text>
					No one is in the position of boss in the digital world. Management A
					core committee is formed by mutual votes among its active members,
					which determines the management.
				</Text>
			</SectionContainer>
			<ManagementImg src={ManagementImage} alt={'About Us'} />
		</AdditionalSection>
	);
};

export default AdditionalInfoSection;
