export const columnsConfig = {
	TokenPurchaseHistory: [
		{ field: 'SNo', headerName: 'S.No.', flex: 1 },
		{ field: 'DonationDate', headerName: 'Donation Date', flex: 2 },
		{ field: 'Amount', headerName: 'Amount', flex: 1 },
		{
			field: 'Status',
			headerName: 'Status',
			flex: 1,
			renderCell: (params) => (
				<span dangerouslySetInnerHTML={{ __html: params.value }} />
			),
		},
	],
	TeamDownline: [
		{ field: 'SNo', headerName: 'S.No.', flex: 1 },
		{ field: 'NAME', headerName: 'Name', flex: 2 },
		{ field: 'UserId', headerName: 'User ID', flex: 1 },
		{ field: 'UserName', headerName: 'User Name', flex: 1 },
		{ field: 'SponsorName', headerName: 'Sponsor Name', flex: 2 },
		{ field: 'SponsorId', headerName: 'Sponsor ID', flex: 1 },
		{ field: 'ActivationDate', headerName: 'Activation Date', flex: 2 },
		{ field: 'Amount', headerName: 'Amount', flex: 1 },
		{ field: 'Level', headerName: 'Level', flex: 1 },
		{
			field: 'ACTION',
			headerName: 'Action',
			flex: 2,
			renderCell: (params) => (
				<span dangerouslySetInnerHTML={{ __html: params.value }} />
			),
		},
	],
	DirectTeam: [
		{ field: 'SNo', headerName: 'S.No.', flex: 1 },
		{ field: 'Name', headerName: 'Name', flex: 2 },
		{ field: 'UserId', headerName: 'User ID', flex: 1 },
		{ field: 'UserName', headerName: 'User Name', flex: 1 },
		{ field: 'ActivationDate', headerName: 'Activation Date', flex: 2 },
		{ field: 'RegistrationDate', headerName: 'Registration Date', flex: 2 },
		{ field: 'StakeAmount', headerName: 'Stake Amount', flex: 1 },
		{
			field: 'Status',
			headerName: 'Status',
			flex: 1,
			renderCell: (params) => (
				<span dangerouslySetInnerHTML={{ __html: params.value }} />
			),
		},
	],
	LevelTeamGenealogy: [
		{ field: 'SNo', headerName: 'S.No.', flex: 1 },
		{ field: 'Name', headerName: 'Name', flex: 2 },
		{ field: 'Username', headerName: 'Username', flex: 1 },
		{ field: 'UserId', headerName: 'User ID', flex: 1 },
		{
			field: 'Level',
			headerName: 'Level',
			flex: 1,
			renderCell: (params) => (
				<span dangerouslySetInnerHTML={{ __html: params.value }} />
			),
		},
		{ field: 'Donation', headerName: 'Donation', flex: 1 },
		{
			field: 'PaidStatus',
			headerName: 'Paid Status',
			flex: 1,
			renderCell: (params) => (
				<span dangerouslySetInnerHTML={{ __html: params.value }} />
			),
		},
		{ field: 'ActivationDate', headerName: 'Activation Date', flex: 2 },
	],
	WithdrawFundHistory: [
		{ field: 'SNo', headerName: 'S.No', flex: 1 },
		{
			field: 'WithdrawDate',
			headerName: 'Withdraw Date',
			flex: 2,
			renderCell: (params) => (
				<span dangerouslySetInnerHTML={{ __html: params.value }} />
			),
		},
		{
			field: 'WithdrawAmount',
			headerName: 'Withdraw Amount',
			flex: 2,
			renderCell: (params) => (
				<span dangerouslySetInnerHTML={{ __html: params.value }} />
			),
		},
		{
			field: 'WithdrawMode',
			headerName: 'Withdraw Mode',
			flex: 1,
			renderCell: (params) => (
				<span dangerouslySetInnerHTML={{ __html: params.value }} />
			),
		},
		{
			field: 'PaymentMode',
			headerName: 'Payment Mode',
			flex: 1,
			renderCell: (params) => (
				<span dangerouslySetInnerHTML={{ __html: params.value }} />
			),
		},
		{
			field: 'WalletType',
			headerName: 'Wallet Type',
			flex: 1,
			renderCell: (params) => (
				<span dangerouslySetInnerHTML={{ __html: params.value }} />
			),
		},
		{ field: 'TransactionNo', headerName: 'Transaction No', flex: 3 },
		{ field: 'Remarks', headerName: 'Remarks', flex: 1 },
		{
			field: 'Status',
			headerName: 'Status',
			flex: 1,
			renderCell: (params) => (
				<span dangerouslySetInnerHTML={{ __html: params.value }} />
			),
		},
	],
	RequestFundHistory: [
		{ field: 'SNo', headerName: 'S.No', flex: 1 },
		{
			field: 'DepositedDate',
			headerName: 'Deposited Date',
			flex: 2,
			renderCell: (params) => (
				<span dangerouslySetInnerHTML={{ __html: params.value }} />
			),
		},
		{
			field: 'DepositedAmount',
			headerName: 'Deposited Amount',
			flex: 2,
			renderCell: (params) => (
				<span dangerouslySetInnerHTML={{ __html: params.value }} />
			),
		},
		{
			field: 'TransactionNo',
			headerName: 'Transaction No',
			flex: 3,
			renderCell: (params) => (
				<span dangerouslySetInnerHTML={{ __html: params.value }} />
			),
		},
		{
			field: 'Status',
			headerName: 'Status',
			flex: 1,
			renderCell: (params) => (
				<span dangerouslySetInnerHTML={{ __html: params.value }} />
			),
		},
	],
	SponsorIncomeList: [
		{ field: 'SNo', headerName: 'S.No', flex: 1 },
		{ field: 'From', headerName: 'From', flex: 2 },
		{ field: 'Amount', headerName: 'Amount', flex: 1 },
		{ field: 'IncomeDate', headerName: 'Income Date', flex: 2 },
		{
			field: 'Income',
			headerName: 'Income',
			flex: 1,
			renderCell: (params) => (
				<span dangerouslySetInnerHTML={{ __html: params.value }} />
			),
		},
		{ field: 'TransactionNo', headerName: 'Transaction No', flex: 3 },
	],
	LevelIncomeList: [
		{ field: 'SNo.', headerName: 'S.No.', flex: 1 },
		{
			field: 'From',
			headerName: 'From',
			flex: 2,
			renderCell: (params) => (
				<span dangerouslySetInnerHTML={{ __html: params.value }} />
			),
		},
		{ field: 'LevelNo', headerName: 'Level No', flex: 1 },
		{ field: 'Amount', headerName: 'Amount', flex: 1 },
		{ field: 'Percentage', headerName: 'Percentage', flex: 1 },
		{
			field: 'Income',
			headerName: 'Income',
			flex: 1,
			renderCell: (params) => (
				<span dangerouslySetInnerHTML={{ __html: params.value }} />
			),
		},
		{ field: 'IncomeDate', headerName: 'Income Date', flex: 2 },
	],
	DirectIncomeList: [
		{ field: 'SNo', headerName: 'S.No.', flex: 1 },
		{
			field: 'FromMember',
			headerName: 'From Member',
			flex: 2,
			renderCell: (params) => (
				<span dangerouslySetInnerHTML={{ __html: params.value }} />
			),
		},
		{ field: 'Amount', headerName: 'Amount', flex: 1 },
		{ field: 'Percentage', headerName: 'Income (%)', flex: 1 },
		{
			field: 'Income',
			headerName: 'Income',
			flex: 1,
			renderCell: (params) => (
				<span dangerouslySetInnerHTML={{ __html: params.value }} />
			),
		},
		{ field: 'EntryDate', headerName: 'Entry Date', flex: 2 },
	],
};
