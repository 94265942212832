import React from 'react';
import styled from 'styled-components';

const Section = styled.section`
	background-color: #666;
	color: #fff;
	text-align: center;
	padding: 3rem 1rem;
	display: flex;
	justify-content: center;

	@media (min-width: 768px) {
		padding: 10rem 5rem;
	}
`;

const Content = styled.div`
	width: 100%;
	max-width: 60%;

	@media (min-width: 768px) {
		max-width: 60%;
	}
`;

const Title = styled.h2`
	font-size: 2.5rem;
	color: #fff;
	margin-bottom: 1rem;

	@media (min-width: 768px) {
		font-size: 3rem;
	}
`;

const Text = styled.p`
	font-size: 1rem;
	color: #ccc;
	margin-bottom: 2rem;

	@media (min-width: 768px) {
		font-size: 1.2rem;
	}
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	gap: 1rem;
`;

const Button = styled.button`
	background-color: ${(props) => (props.primary ? '#e74c3c' : 'transparent')};
	color: ${(props) => (props.primary ? '#fff' : '#fff')};
	border: ${(props) => (props.primary ? 'none' : '1px solid #fff')};
	padding: 0.75rem 1.5rem;
	font-size: 1rem;
	cursor: pointer;
	transition: background-color 0.3s ease;

	&:hover {
		background-color: ${(props) => (props.primary ? '#c0392b' : '#444')};
	}

	@media (min-width: 768px) {
		font-size: 1.2rem;
	}
`;

const JoinCommunitySection = () => {
	return (
		<Section>
			<Content>
				<Title>JOIN OUR META COMMUNITY</Title>
				<Text>
					Joining The Crowd World Community trust provides numerous benefits,
					including access to a network of like-minded individuals,
					opportunities to collaborate on innovative projects, and a supportive
					community that fosters personal and professional growth. Members also
					have the chance to contribute to a greater cause by advancing digital
					technology for the betterment of society.
				</Text>
				<ButtonContainer>
					<Button primary>SIGN IN</Button>
					<Button>SIGN UP</Button>
				</ButtonContainer>
			</Content>
		</Section>
	);
};

export default JoinCommunitySection;
