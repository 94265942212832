import React from 'react';
import styled from 'styled-components';

const Section = styled.section`
	padding: 2rem 1rem;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const Grid = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 1rem;
	max-width: 1200px;
	width: 100%;

	@media (max-width: 1024px) {
		grid-template-columns: repeat(2, 1fr);
	}

	@media (max-width: 768px) {
		grid-template-columns: 1fr;
	}
`;

const Card = styled.div`
	background: #fff;
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	padding: 1.5rem;
	text-align: center;
`;

const CardIcon = styled.div`
	font-size: 2rem;
	margin-bottom: 1rem;
`;

const CardTitle = styled.h3`
	font-size: 1.25rem;
	margin-bottom: 0.5rem;
`;

const CardDescription = styled.p`
	color: #666;
`;

const cardsData = [
	{
		icon: '📊',
		title: 'AutoPool',
		description:
			'P2P support system For crowd funding program with Social welfare',
	},
	{
		icon: '💖',
		title: 'Welfare',
		description: 'We help our members according to their needs',
	},
	{
		icon: '🎮',
		title: 'Game Zone',
		description:
			'Smart Features a catalog of the most exciting, innovative, and rewarding blockchain & Crypto enabled games.',
	},
	{
		icon: '🛒',
		title: 'E-commerce',
		description:
			'E-commerce With accepting cryptocurrency their online stores.',
	},
	{
		icon: '💱',
		title: 'Exchange',
		description:
			'Simply and securely buy, sell, and manage of crypto-currencies',
	},
	{
		icon: '🤖',
		title: 'Trading Bot',
		description:
			'Trading Bot is software that helps you automate your trading strategies.',
	},
	{
		icon: '🔧',
		title: 'Utility Services',
		description: 'Utility services are services provided',
	},
	{
		icon: '🌐',
		title: 'Community metaverse',
		description: 'Virtual world for socializing, commerce, and Cooperation.',
	},
];

const CardSection = () => {
	return (
		<Section id="dwc-technology">
			<Grid>
				{cardsData.map((card, index) => (
					<Card key={index}>
						<CardIcon>{card.icon}</CardIcon>
						<CardTitle>{card.title}</CardTitle>
						<CardDescription>{card.description}</CardDescription>
					</Card>
				))}
			</Grid>
		</Section>
	);
};

export default CardSection;
