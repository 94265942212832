import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
	Container,
	Box,
	TextField,
	Button,
	Checkbox,
	FormControlLabel,
	Typography,
	Select,
	MenuItem,
	InputLabel,
	FormControl,
	Alert,
	InputAdornment,
	IconButton,
} from '@mui/material';
import {
	Lock,
	Person,
	Email,
	VpnKey,
	Public,
	Visibility,
	VisibilityOff,
} from '@mui/icons-material';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import config from '../common/config';
import { countries } from '../common/Lookup';
import CryptoJS from 'crypto-js';
import Swal from 'sweetalert2';
const FormWrapper = styled(Box)`
	background: linear-gradient(180deg, #e0e0e0, #f0f0f0);
	padding: 1rem;
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	max-width: 500px;
	width: 100%;
	max-height: 90vh;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const ImageWrapper = styled(Box)`
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin-bottom: 1rem;
`;

const FormTitle = styled(Typography)`
	color: #2c3e50;
	margin-bottom: 0.5rem;
`;

const FormSubTitle = styled(Typography)`
	color: #16a085;
	margin-bottom: 1rem;
`;

const SignUp = () => {
	const [formData, setFormData] = useState({
		sponsorId: '',
		name: '',
		username: '',
		password: '',
		confirmPassword: '',
		country: '',
		email: '',
		otp: '',
		terms: false,
	});
	const [error, setError] = useState('');
	const [isFormValid, setIsFormValid] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		const isValid =
			Object.values(formData).every((value) => value) && formData.terms;
		setIsFormValid(isValid);
	}, [formData]);

	const location = useLocation();

	useEffect(() => {
		const params = new URLSearchParams(location.search);
		const sponsorId = params.get('sponsorId');
		const encryptedUsername = params.get('username');
		let decryptedUsername = '';

		if (encryptedUsername) {
			const bytes = CryptoJS.AES.decrypt(
				decodeURIComponent(encryptedUsername),
				'Usr@#$123'
			);
			decryptedUsername = bytes.toString(CryptoJS.enc.Utf8);
		}

		if (sponsorId) {
			setFormData((prevData) => ({
				...prevData,
				sponsorId: sponsorId,
			}));
		}
	}, [location]);

	const server_url = config.server_url;

	const handleChange = (e) => {
		const { name, value, type, checked } = e.target;
		setFormData((prevData) => ({
			...prevData,
			[name]: type === 'checkbox' ? checked : value,
		}));
	};

	const togglePasswordVisibility = () => {
		setShowPassword((prevShowPassword) => !prevShowPassword);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const response = await axios.post(`${server_url}/register`, formData, {
				headers: {
					'Content-Type': 'application/json',
				},
			});
			console.log(response);
			if (response.data.success) {
				navigate('/login');
			} else {
				setError('Something went wrong');
			}
		} catch (err) {
			setError('Something went wrong');
		}
	};

	const handleGetOtp = async (e) => {
		try {
			const response = await axios.post(
				`${config.server_url}/send-register-otp`,
				{
					email: formData.email,
				},
				{
					headers: {
						'Content-Type': 'application/json',
					},
				}
			);
			if (response.data.success) {
				Swal.fire('Success', 'OTP sent successfully', 'success');
			} else {
				Swal.fire('Error', 'OTP sent failed', 'error');
			}
		} catch (err) {
			setError('Something went wrong');
		}
	};

	return (
		<Container
			sx={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				height: '100vh',
				backgroundColor: '#f9f9f9',
			}}>
			<FormWrapper>
				<ImageWrapper>
					<img
						src='signup.png'
						alt='Sign Up'
						style={{ width: '50%', borderRadius: '8px' }}
					/>
					<img
						src='logo.png'
						alt='Logo'
						style={{ width: '30%', borderRadius: '8px' }}
					/>
				</ImageWrapper>
				<FormTitle variant='h4'>Register</FormTitle>
				<FormSubTitle variant='subtitle1'>On The Digital World</FormSubTitle>
				{error && (
					<Alert severity='error' sx={{ width: '100%', mb: 2 }}>
						{error}
					</Alert>
				)}
				<form onSubmit={handleSubmit}>
					<TextField
						fullWidth
						variant='outlined'
						margin='dense'
						placeholder='Enter Sponsor ID'
						label='Sponsor ID'
						required
						name='sponsorId'
						value={formData.sponsorId}
						onChange={handleChange}
						InputProps={{
							startAdornment: (
								<Person sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
							),
						}}
					/>
					<TextField
						fullWidth
						variant='outlined'
						margin='dense'
						placeholder='Enter Name'
						label='Name'
						required
						name='name'
						value={formData.name}
						onChange={handleChange}
						InputProps={{
							startAdornment: (
								<Person sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
							),
						}}
					/>
					<TextField
						fullWidth
						variant='outlined'
						margin='dense'
						placeholder='Enter Username'
						label='Username'
						required
						name='username'
						value={formData.username}
						onChange={handleChange}
						InputProps={{
							startAdornment: (
								<Person sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
							),
						}}
					/>
					<TextField
						fullWidth
						variant='outlined'
						margin='dense'
						placeholder='Enter Password'
						label='Password'
						type={showPassword ? 'text' : 'password'}
						required
						name='password'
						value={formData.password}
						onChange={handleChange}
						InputProps={{
							startAdornment: (
								<Lock sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
							),
							endAdornment: (
								<InputAdornment position='end'>
									<IconButton onClick={togglePasswordVisibility} edge='end'>
										{showPassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
					<TextField
						fullWidth
						variant='outlined'
						margin='dense'
						placeholder='Confirm Password'
						label='Confirm Password'
						type={showPassword ? 'text' : 'password'}
						required
						name='confirmPassword'
						value={formData.confirmPassword}
						onChange={handleChange}
						InputProps={{
							startAdornment: (
								<Lock sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
							),
							endAdornment: (
								<InputAdornment position='end'>
									<IconButton onClick={togglePasswordVisibility} edge='end'>
										{showPassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
					<FormControl fullWidth margin='dense'>
						<InputLabel id='country-label'>Country</InputLabel>
						<Select
							labelId='country-label'
							label='Country'
							name='country'
							value={formData.country}
							onChange={handleChange}
							startAdornment={
								<Public sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
							}
							MenuProps={{
								PaperProps: {
									style: {
										maxHeight: 200,
										width: 200,
									},
								},
							}}>
							<MenuItem value='select'>
								<em>None</em>
							</MenuItem>
							{countries.map((country) => (
								<MenuItem key={country.id} value={country.code}>
									{country.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<TextField
						fullWidth
						variant='outlined'
						margin='dense'
						placeholder='Enter Email Address'
						label='Email Address'
						type='email'
						required
						name='email'
						value={formData.email}
						onChange={handleChange}
						InputProps={{
							startAdornment: (
								<Email sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
							),
						}}
					/>
					<Box display='flex' alignItems='center'>
						<TextField
							fullWidth
							variant='outlined'
							margin='dense'
							placeholder='Enter OTP Code'
							label='OTP Code'
							required
							name='otp'
							value={formData.otp}
							onChange={handleChange}
							InputProps={{
								startAdornment: (
									<VpnKey sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
								),
							}}
						/>
						<Button
							variant='contained'
							color='success'
							sx={{ marginLeft: '1rem', height: '50px', width: '200px' }}
							onClick={handleGetOtp}>
							Get OTP
						</Button>
					</Box>
					<FormControlLabel
						control={
							<Checkbox
								required
								name='terms'
								checked={formData.terms}
								onChange={handleChange}
							/>
						}
						label='I have read and agree to the Terms & Conditions'
						sx={{ alignSelf: 'flex-start' }}
					/>
					<Button
						type='submit'
						variant='contained'
						color='primary'
						fullWidth
						disabled={!isFormValid}
						sx={{
							padding: '0.75rem',
							marginTop: '1rem',
							background: isFormValid
								? 'linear-gradient(to right, #16a085, #1abc9c)'
								: 'grey',
						}}>
						REGISTER
					</Button>
				</form>
				<Box mt={2}>
					<Typography>
						Already Registered?{' '}
						<a
							href='/login'
							style={{ color: '#16a085', textDecoration: 'none' }}>
							Sign in here
						</a>
					</Typography>
				</Box>
			</FormWrapper>
		</Container>
	);
};

export default SignUp;
