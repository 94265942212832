import React from 'react';
import GridPage from '../GridPage';

const StakingHistory = () => {
	return (
		<GridPage
			pageName='TokenPurchaseHistory'
			title='Your Staking History'
			requiredSection={{
				globalFilter: true,
				levelFilter: false,
				status: true,
			}}
		/>
	);
};

export default StakingHistory;
