const welfareData = [
	{
		title: 'Medical Emergency Fund Support',
		text: 'In case of need of big funds for medical emergency, any member can request the community to raise funds by sending their complete reports through support.',
		imageUrl: '/images/medical.png',
	},
	{
		title: 'Family Survival Fund Support',
		text: 'After the demise of any member, for the survival of his family, after verifying by the admin core member, the community as possible fund is to be raised through online campaigning and given to them in the form of MIS.',
		imageUrl: '/images/family.png',
	},
	{
		title: 'Education fund support',
		text: 'For any uncomfortable meritorious students of the society, after verifying by the admin and core members for their final admission, they are to be helped by collecting as much funds as possible by the community through online campaigning.',
		imageUrl: '/images/education.png',
	},
	{
		title: 'Marriage fund support',
		text: 'The arrangement for mass marriage of common adult girls is to be helped by the community by collecting funds through campaigning with the help of admin and core members.',
		imageUrl: '/images/wedding.png',
	},
	{
		title: 'Natural Disaster Fund Support',
		text: 'Funds are to be collected for natural disaster management through online campaigning with the help of community, core members and admin.',
		imageUrl: '/images/weather.png',
	},
	{
		title: 'Equity Based Start-up Fund Support',
		text: 'Based on experience and potential any member can take interest free non-refundable fund support for his/her individual project on project profit share basis with verification and cooperation of admin core member and community.',
		imageUrl: '/images/startup.png',
	},
	{
		title: 'Happy Home Gurukul Startup',
		text: 'Under which arrangements for residential schools as well as old age homes are to be made at the state level with the cooperation of the admin core members and the community.',
		imageUrl: '/images/home.png',
	},
	{
		title: 'Campaign Program',
		text: 'Digital world campaigns some programs continuously and some from time to time, which helps in maintaining the values of humanity, we want you to be a part of our campaign and introduce human values.',
		imageUrl: '/images/campaign.png',
	},
];

export default welfareData;
