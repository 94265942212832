import React from 'react';
import { Box, Grid, Typography, Button, TextField, MenuItem, FormControl, Select, InputLabel } from '@mui/material';
import { styled } from '@mui/system';

const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: '#294e46',
  color: '#fff',
  borderRadius: theme.shape.borderRadius,
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1),
  },
}));

const TicketBox = styled(Box)(({ theme }) => ({
  border: '1px solid #fff',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  color: '#fff',
}));

const SummaryBox = styled(Box)(({ theme }) => ({
  border: '1px solid #fff',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  color: '#fff',
}));

const SupportQueries = () => {
  return (
    <Container>
      <Typography variant="h6" gutterBottom>Support</Typography>
      <Typography variant="subtitle1" gutterBottom>See all your queries here</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <TicketBox>
            <Button fullWidth variant="contained" style={{ marginBottom: 8 }}>Close Tickets: 0</Button>
            <Button fullWidth variant="outlined" color="error" style={{ marginBottom: 8 }}>Open Tickets: 0</Button>
            <Button fullWidth variant="contained">Total Tickets: 0</Button>
            <Button fullWidth variant="contained" color="primary" style={{ marginTop: 16 }}>+ Post New Query</Button>
          </TicketBox>
        </Grid>
        <Grid item xs={12} md={8}>
          <SummaryBox>
            <Typography variant="h6" gutterBottom>All Posted Queries Summary</Typography>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel style={{ color: '#fff' }}>Select Type</InputLabel>
                  <Select
                    label="Select Type"
                    native
                    inputProps={{
                      style: { color: '#fff' },
                    }}
                  >
                    <option value="">--Select Type--</option>
                    {/* Add options here */}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Search By Text"
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    style: { color: '#fff' },
                  }}
                  InputProps={{
                    style: { color: '#fff' },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel style={{ color: '#fff' }}>Status</InputLabel>
                  <Select
                    label="Status"
                    native
                    inputProps={{
                      style: { color: '#fff' },
                    }}
                  >
                    <option value="Open">Open</option>
                    <option value="Closed">Closed</option>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Button fullWidth variant="contained" color="primary">Search</Button>
              </Grid>
            </Grid>
            <Typography variant="body1" style={{ marginTop: '16px', color: 'yellow' }}>
              No record found!! Try to change filters
            </Typography>
          </SummaryBox>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SupportQueries;
