import React from 'react';
import { Box, Grid, Typography, Button, TextField } from '@mui/material';
import { styled } from '@mui/system';
import QRCode from 'qrcode.react';

const Container = styled(Box)(({ theme }) => ({
	padding: theme.spacing(2),
	backgroundColor: '#294e46',
	color: '#fff',
	borderRadius: theme.shape.borderRadius,
	[theme.breakpoints.down('sm')]: {
		padding: theme.spacing(1),
	},
}));

const QRCodeBox = styled(Box)(({ theme }) => ({
	border: '1px solid #fff',
	borderRadius: theme.shape.borderRadius,
	padding: theme.spacing(2),
	textAlign: 'center',
	color: '#fff',
}));

const AddressBox = styled(Box)(({ theme }) => ({
	border: '1px solid #fff',
	borderRadius: theme.shape.borderRadius,
	padding: theme.spacing(1),
	marginTop: theme.spacing(2),
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	color: '#fff',
}));

const RequestFund = () => {
	const walletAddress = '0xB32Bef11c2022Bf15d28Cb5012490a5e64f5aAb93';

	const handleCopyAddress = () => {
		navigator.clipboard.writeText(walletAddress);
		alert('Address copied to clipboard');
	};

	return (
		<Container>
			<Typography variant='h6' gutterBottom>
				Request Fund (USDT)
			</Typography>
			<Typography variant='subtitle1' gutterBottom>
				Deposit Fund By Scanning QR Code
			</Typography>
			<QRCodeBox>
				<Typography variant='h6' gutterBottom>
					Chain Name: USDT BEP20
				</Typography>
				<Typography variant='body1' gutterBottom>
					Deposit by scanning this QR Code
				</Typography>
				<Typography variant='h4' gutterBottom>
					Balance: 0 USD
				</Typography>
				<QRCode value={walletAddress} size={128} />
			</QRCodeBox>
			<AddressBox>
				<TextField
					value={walletAddress}
					fullWidth
					InputProps={{
						readOnly: true,
						style: { color: '#fff' },
					}}
					variant='outlined'
				/>
				<Button
					variant='contained'
					color='primary'
					onClick={handleCopyAddress}
					style={{ marginLeft: 8 }}>
					Copy
				</Button>
			</AddressBox>
		</Container>
	);
};

export default RequestFund;
