import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../common/config';
import moment from 'moment';
const AuthContext = createContext();

export const useAuth = () => {
	return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
	const [token, setToken] = useState(null);
	const navigate = useNavigate();

	useEffect(() => {
		const storedToken = localStorage.getItem('token');
		if (storedToken) {
			setToken(storedToken);
		}
	}, []);

	const initialData = {
		Id: '',
		userId: '',
		sponsorId: '',
		name: '',
		username: '',
		email: '',
		terms: '',
		phoneNumber: '',
		firstName: '',
		mobileNo: '',
		dateOfBirth: '',
		pinCode: '',
		lastLogin: '',
		language: 'English',
		profileImage: '/images/profile.png',
		myWallet: 0,
		fundWallet: 0,
		autoPoolWallet: 0,
		boostingWallet: 0,
	};
	const [profile, setProfile] = useState(initialData);

	const login = (newToken) => {
		setToken(newToken);
		localStorage.setItem('token', newToken);
		navigate('/dashboard');
	};

	const logout = () => {
		setToken(null);
		localStorage.removeItem('token');
		document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
		navigate('/login');
	};

	const loadUserInfo = async (userId, token) => {
		try {
			debugger;
			const response = await axios.get(`${config.server_url}/userInfo`, {
				params: { userId },
				headers: {
					Authorization: `Bearer ${token}`,
					'Content-Type': 'application/json',
				},
				withCredentials: true,
			});

			const data = response?.data?.data;
			setProfile({
				Id: data.Id,
				userId: data.UserId,
				sponsorId: data.sponsorId,
				name: data.name,
				username: data.username,
				email: data.email,
				terms: data.terms,
				phoneNumber: data.phoneNumber,
				firstName: data.firstName,
				mobileNo: data.mobileNo,
				dateOfBirth: data.dateOfBirth,
				pinCode: data.pinCode,
				lastLogin: data.LastLoginDate
					? moment(data.LastLoginDate).format('DD-MMM-YYYY HH:mm:ss')
					: '',
				language: data.language,
				profileImage: data.profileImage || '/images/family.png',
				myWallet: data.MyWalletBalance || 0,
				fundWallet: data.FundWalletBalance || 0,
				autoPoolWallet: data.AutoPoolWalletBalance || 0,
				boostingWallet: data.BoostingWalletBalance || 0,
			});
		} catch (error) {
			console.error('Error fetching profile data:', error);
		}
	};

	const resetAppState = () => {
		setToken(null);
		setProfile(initialData);
	};
	return (
		<AuthContext.Provider
			value={{ profile, loadUserInfo, token, login, logout }}>
			{children}
		</AuthContext.Provider>
	);
};
