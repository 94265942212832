import React from 'react';
import { Grid, TextField, MenuItem } from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { DATE_FORMATS } from '../utils/common';
import { GradientButton } from './commonStyle';

const GlobalFilter = ({ filters, handleFilterChange, handleSearch }) => {
	const inputCustomStyle = {
		background:'linear-gradient(81deg, #0f243e 0%, #4fb1e0 100%)',
		borderRadius: 1,
		'& .MuiInputBase-root': {
			color: '#fff',
		},
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: '#fff',
		},
		'& .MuiInputLabel-root': {
			color: '#fff',
		},
	};

	const CustomDateInput = React.forwardRef((props, ref) => (
		<TextField
			{...props}
			inputRef={ref}
			variant='outlined'
			fullWidth
			sx={inputCustomStyle}
			InputLabelProps={{ shrink: true }}
		/>
	));
	return (
		<Grid container spacing={2} alignItems='center' sx={{ mb: 2, mt: 1 }}>
			<Grid item xs={12} sm={2}>
				<DatePicker
					selected={filters.FromDate}
					onChange={(date) => handleFilterChange('FromDate', date)}
					dateFormat={DATE_FORMATS.DISPLAY_FORMAT}
					customInput={<CustomDateInput label='From Date' />}
				/>
			</Grid>
			<Grid item xs={12} sm={2}>
				<DatePicker
					selected={filters.ToDate}
					onChange={(date) => handleFilterChange('ToDate', date)}
					dateFormat={DATE_FORMATS.DISPLAY_FORMAT}
					customInput={<CustomDateInput label='To Date' />}
				/>
			</Grid>

			<Grid item xs={12} sm={3}>
				<TextField
					select
					label='Select Status'
					value={filters.status}
					onChange={(event) => handleFilterChange('Status', event.target.value)}
					variant='outlined'
					fullWidth
					sx={inputCustomStyle}>
					<MenuItem value='Pending'>Pending</MenuItem>
					<MenuItem value='Success'>Approved</MenuItem>
					<MenuItem value='Rejected'>Rejected</MenuItem>
				</TextField>
			</Grid>
			<Grid item xs={12} sm={2}>
				<GradientButton onClick={handleSearch} text='Search' />
			</Grid>
		</Grid>
	);
};

export default GlobalFilter;
