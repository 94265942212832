import React, { useState } from 'react';
import { Box, Grid, TextField, MenuItem, Button, Typography, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from '@mui/material';
import { styled } from '@mui/system';

const Container = styled(Box)(({ theme }) => ({
	padding: theme.spacing(2),
	backgroundColor: '#294e46',
	color: '#fff',
	borderRadius: theme.shape.borderRadius,
	[theme.breakpoints.down('sm')]: {
		padding: theme.spacing(1),
	},
}));

const WalletBox = styled(Box)(({ theme }) => ({
	border: '1px solid #fff',
	borderRadius: theme.shape.borderRadius,
	padding: theme.spacing(2),
	marginBottom: theme.spacing(2),
	color: '#fff',
}));

const WalletTransfer = () => {
	const [selectedWallet, setSelectedWallet] = useState('own');
	const [walletAddress, setWalletAddress] = useState('0xB32Bef11c2022Bf15d28Cb5012490a5e64f5aAb93');

	const handleCopyAddress = () => {
		navigator.clipboard.writeText(walletAddress);
		alert('Address copied to clipboard');
	};

	return (
		<Container>
			<Typography variant="h6" gutterBottom>Wallet Transfer</Typography>
			<Typography variant="subtitle1" gutterBottom>Withdraw your business earnings</Typography>
			<WalletBox>
				<Typography variant="h6" gutterBottom>Wallet Balance</Typography>
				<Typography variant="h3" gutterBottom>$0</Typography>
				<Typography variant="body2" gutterBottom>Last updated:</Typography>
				<FormControl component="fieldset">
					<FormLabel component="legend" style={{ color: '#fff' }}>Where you want to transfer?</FormLabel>
					<RadioGroup
						row
						value={selectedWallet}
						onChange={(e) => setSelectedWallet(e.target.value)}
					>
						<FormControlLabel value="own" control={<Radio style={{ color: '#fff' }} />} label="Your Own Wallet" />
						<FormControlLabel value="other" control={<Radio style={{ color: '#fff' }} />} label="Other User Wallet" />
					</RadioGroup>
				</FormControl>
				<TextField
					select
					label="From Wallet"
					fullWidth
					variant="outlined"
					margin="normal"
					SelectProps={{
						native: true,
					}}
					InputLabelProps={{
						style: { color: '#fff' },
					}}
					InputProps={{
						style: { color: '#fff' },
					}}
				>
					<option value="">Select From Wallet</option>
					{/* Add wallet options here */}
				</TextField>
				<TextField
					select
					label="To Wallet"
					fullWidth
					variant="outlined"
					margin="normal"
					SelectProps={{
						native: true,
					}}
					InputLabelProps={{
						style: { color: '#fff' },
					}}
					InputProps={{
						style: { color: '#fff' },
					}}
				>
					<option value="">Select To Wallet</option>
					{/* Add wallet options here */}
				</TextField>
				<TextField
					label="UserName"
					fullWidth
					variant="outlined"
					margin="normal"
					InputLabelProps={{
						style: { color: '#fff' },
					}}
					InputProps={{
						style: { color: '#fff' },
					}}
					value="Shivam123"
				/>
				<Box mt={1} mb={1}>
					<Button variant="contained" color="success">Shivam tiwari</Button>
				</Box>
				<TextField
					label="Transfer Amount"
					fullWidth
					variant="outlined"
					margin="normal"
					InputLabelProps={{
						style: { color: '#fff' },
					}}
					InputProps={{
						style: { color: '#fff' },
					}}
				/>
				<Grid container spacing={2}>
					<Grid item xs={9}>
						<TextField
							label="OTP"
							fullWidth
							variant="outlined"
							margin="normal"
							InputLabelProps={{
								style: { color: '#fff' },
							}}
							InputProps={{
								style: { color: '#fff' },
							}}
						/>
					</Grid>
					<Grid item xs={3}>
						<Button variant="contained" color="primary" fullWidth style={{ marginTop: 16 }}>Send OTP</Button>
					</Grid>
				</Grid>
				<Button variant="contained" color="primary" fullWidth style={{ marginTop: 16 }}>Submit</Button>
			</WalletBox>
		</Container>
	);
};

export default WalletTransfer;

