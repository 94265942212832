import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Button from '../components/Button';
import heroImage from '../assets/images/hero.jpg';
import CardSection from '../components/CardSection';
import JoinSection from '../components/JoinSection';
import ActivitiesSection from '../components/ActivitiesSection';
import ValuesSection from '../components/ValuesSection';
import WelfareSection from '../components/WelfareSection';
import AboutUsSection from '../components/AboutUsSection';
import AdditionalInfoSection from '../components/AdditionalInfoSection';
import JoinCommunitySection from '../components/JoinCommunitySection';
import ContactForm from '../components/ContactForm';
const HeroSection = styled.section`
	height: 100vh;
	background: url(${heroImage}) no-repeat center center/cover;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	color: white;
	text-align: center;
`;

const Title = styled.h1`
	font-size: 3rem;
	margin-bottom: 1rem;
`;

const Subtitle = styled.p`
	font-size: 1.5rem;
	margin-bottom: 2rem;
	max-width: 600px;
`;

const ButtonGroup = styled.div`
	display: flex;
	gap: 1rem;
`;

const Home = () => (
	<>
		{' '}
		<HeroSection>
			<Title>The Digital World Community</Title>
			<Subtitle>
				Community is a trustworthy entity that promotes collaboration and
				innovation within the digital landscape, fostering a sense of belonging
				and shared purpose among its members.
			</Subtitle>
			<ButtonGroup>
				<Link to='/login'>
					<Button primary>LOG IN</Button>
				</Link>
				<Link to='/signup'>
					<Button>SIGN UP</Button>
				</Link>
			</ButtonGroup>
		</HeroSection>
		<CardSection />
		<JoinSection />
		<ActivitiesSection />
		<ValuesSection />
		<WelfareSection />
		<AboutUsSection />
		<AdditionalInfoSection />
		<JoinCommunitySection />
		<ContactForm />
	</>
);

export default Home;
