import React, { useState, useEffect } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { styled } from '@mui/system';
import DynamicGrid from './DynamicGrid';
import { CommonHeader } from './commonStyle';
import useGridData from '../hooks/useGridData';
import GlobalFilter from './GlobalFilter';
import { LevelFilter } from './CustomFilter';
import { useAuth } from '../hooks/AuthContext';
import moment from 'moment';

const Container = styled(Box)(({ theme }) => ({
	padding: theme.spacing(2),
	background:'linear-gradient(81deg, #0f243e 0%, #4fb1e0 100%)',
	color: '#fff',
	borderRadius: theme.shape.borderRadius,
	[theme.breakpoints.down('sm')]: {
		padding: theme.spacing(1),
	},
}));

const GridPage = ({ pageName, title, requiredSection }) => {
	const { profile } = useAuth();
	const [filters, setFilters] = useState({
		UserId: profile.Id,
		FromDate: moment().format('DD-MMMM-YYYY'),
		ToDate: moment().format('DD-MMMM-YYYY'),
		Level: 1,
		Status: 'Success',
	});
	const [searchTriggered, setSearchTriggered] = useState(false);

	const handleFilterChange = (filterName, filterValue) => {
		setFilters((prevFilters) => ({
			...prevFilters,
			[filterName]: filterValue,
		}));
	};

	const handleSearch = () => {
		setSearchTriggered(true);
	};

	const { columns, rows, loading } = useGridData(pageName, filters);

	useEffect(() => {
		if (searchTriggered) {
			setSearchTriggered(false);
		}
	}, [searchTriggered, filters, pageName]);

	return (
		<Container>
			<CommonHeader variant='h6' gutterBottom>
				{title}
			</CommonHeader>
			{requiredSection.globalFilter && (
				<GlobalFilter
					filters={filters}
					handleFilterChange={handleFilterChange}
					handleSearch={handleSearch}
				/>
			)}
			{requiredSection.levelFilter && (
				<LevelFilter
					filters={filters}
					handleFilterChange={handleFilterChange}
					handleSearch={handleSearch}
				/>
			)}
			<Divider sx={{ borderColor: '#fff', mb: 2 }} />
			{loading ? (
				<Typography variant='body1'>Loading...</Typography>
			) : (
				<DynamicGrid
					columns={columns}
					rows={rows}
					showSearch={true}
					printButton={{ active: true, text: 'Print' }}
					excelButton={{ active: true, text: 'Excel' }}
				/>
			)}
		</Container>
	);
};

export default GridPage;
